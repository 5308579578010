import { useEffect, useState } from 'react';
import {AreaChart, XAxis, YAxis, CartesianGrid, Label, Tooltip, Area, ResponsiveContainer} from 'recharts'
import axios from 'axios';
import User from '../../../api/user';
import { IoIosArrowDropright, IoIosArrowDropleft, IoIosArrowDroprightCircle, IoIosArrowDropleftCircle } from "react-icons/io";
import "./dayAheadPriceChart.css"

const DayAheadPriceChart = ({dateToday, interval}) => {
    const [data, setData] = useState("");
    const [currentDate, setCurrentDate] = useState(new Date(dateToday));
    const [axisInterval, setAxisInterval] = useState(1);
    const [domainValeus, setDomainValues] = useState([-1, 1])
    const userApi = User();

    useEffect(() => {
        
        if (interval == 'Phone') {
            setAxisInterval(3)
        }
        fetchData();

    }, [currentDate])

    const fetchData = async () => {

        
        try {
            const response = await userApi.getDayAheadPrice();

            const dataWithConvertedPrice = []
            response.time.map((timestamp, i) => {
                if (timestamp.split("T")[0] == currentDate.toISOString().split("T")[0]) {

                    dataWithConvertedPrice.push({dateTime: timestamp, price: response.values[i]})
                }
            })

            let highestPrice = Math.max(...dataWithConvertedPrice.map(item => item.price));
            let lowestPrice = Math.min(...dataWithConvertedPrice.map(item => item.price));

            if (lowestPrice > 0) {
                lowestPrice = 0;
            }
            if (highestPrice < 0) {
                highestPrice = 0;
            }

            setDomainValues([lowestPrice, highestPrice])
        
            setData(dataWithConvertedPrice);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const OnBackButtonClick = () => {
        const selectedDay = new Date(currentDate);
        const today = new Date();
      
        // Calculate the minimum allowed date (7 days before today)
        const minAllowedDate = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000)); // 6 days in milliseconds
      
        if (!(selectedDay.getTime() < minAllowedDate.getTime())) {
            selectedDay.setDate(selectedDay.getDate() - 1);
            setCurrentDate(selectedDay);
        }
    };

    const OnForwardButtonClick = () => {
        //allow only +1 day
        const selectedDay = new Date(currentDate);
        const today = new Date();
      
        const maxAllowedDate = new Date(today.getTime() + (1 * 24 * 60 * 60 * 1000));

        if (selectedDay.getTime() + (1 * 24 * 60 * 60 * 1000) < maxAllowedDate.getTime()) {
            selectedDay.setDate(selectedDay.getDate() + 1);
            setCurrentDate(selectedDay);
        }
    }


    return (
        <div style={{display:'flex', flexFlow:"column nowrap", alignItems:"flex-end", width:"100%", height:"100%"}}>
            <ResponsiveContainer width="100%" height={"75%"}>
                <AreaChart data={data} margin={{ top: 10, right: 30, left: 10, bottom: 0 }}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#65D8A0" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#65D8A0" stopOpacity={0.1}/>
                        </linearGradient>
                    </defs>
                    <XAxis
                        stroke="white"
                        dataKey="dateTime"
                        interval={axisInterval}
                        tickFormatter={(value) => {
                            const date = new Date(value);
                            return `${date.getHours()}u`;
                        }}
                    />
                    <YAxis stroke='white' dataKey="price" unit="€" domain={[domainValeus[0] + domainValeus[0] * 0.05, domainValeus[1] + domainValeus[1] * 0.05]} tickFormatter={(value) => value.toFixed(3)}/>
                    <Tooltip labelFormatter={(value) => new Date(value).toLocaleString()} formatter={(value) => [`Price: ${value} €/kWh`]}/>
                    <Area type="monotone" dataKey="price" stroke="#65D8A0" fillOpacity={1} fill="url(#colorUv)" />
                </AreaChart>
            </ResponsiveContainer>
            <div style={{display:'flex', flexFlow:"row", justifyContent:"flex-start", alignItems:"center", marginRight:"40px", borderRadius:"5px"}}>
                <div style={{display:"flex", alignItems:"center", paddingRight:"5px"}}><button style={{background:"none", border:"none"}} className='DayAheadPriceChartBackBtn' onClick={OnBackButtonClick}><IoIosArrowDropleftCircle style={{ width: '20px', height: '20px' }} color='white'/></button></div>
                <div style={{color:"white", padding:"2px 5px", width:"px", textAlign:"center"}}>{currentDate.toLocaleDateString()}</div>
                <div className='DayAheadPriceChartForwardBtn' style={{display:"flex", alignItems:"center", padding:"0px 5px"}}><button style={{background:"none", border:"none"}} className='DayAheadPriceChartBackBtn' onClick={OnForwardButtonClick}><IoIosArrowDroprightCircle style={{ width: '20px', height: '20px' }} color='white'/></button></div>
            </div>
        </div>
        
    )


}


export default DayAheadPriceChart;