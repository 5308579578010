import React from 'react';
import './underConstruction.css';

function UnderConstruction() {
    return (
        <div className="under-construction">
            <div className="construction-icon">&#128679;</div> {/* Construction emoji */}
            <h1 className='construction-h1'>We're working on it!</h1>
            <p className='construction-p'>This page is currently under construction. Please check back later.</p>
        </div>
    );
}

export default UnderConstruction;