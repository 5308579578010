import axios from "axios";
import { format, parse, addDays } from 'date-fns';

const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

const GraphValues = () => {

    const getGraphValue = async(request, serialNr, date, interval = '1h') => {

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: baseUrl + 'graphValue/' + request,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const tommorow = addDays(date, 1);

        const data = JSON.stringify({
            clientID: serialNr,
            start_date: format(date, 'yyyy-MM-dd'), //actual date
            end_date: format(tommorow, 'yyyy-MM-dd'), //tommorow's date
            tz: 'Europe/Brussels',
            interval: interval ,
        })

        try {
            const response = await axios.post(config.url, data, config);

            if (request == "solarProduction" || request == "consumption" || request == "export") {
                
                const check = response.data.map(num => {
                    return {
                      "time": num.time,
                      "value": Math.abs(num.value)
                    };
                });
                
                return check
            } else {
                return response.data
            }
        } catch (error) {
            throw error;
        }

    }

    return {
        getGraphValue,
    }

}

export default GraphValues