import axios from "axios";
import { format, parse, addDays } from 'date-fns';

const baseUrl = process.env.REACT_APP_DATA_SERVICE_URL;

const Policy = () => {

    const getPolicys = async(serialNr, date) => {

        const credentials = btoa(`${process.env.REACT_APP_DATA_SERVICE_USERNAME}:${process.env.REACT_APP_DATA_SERVICE_PASSWORD}`);

        const tommorow = addDays(date, 1);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: baseUrl + '/api/historic/policy/' + serialNr,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${credentials}`
            },
            params: {
                timezone: 'Europe/Brussels',
                start_date: format(date, 'yyyy-MM-dd'),
                end_date: format(tommorow, 'yyyy-MM-dd'),
                element: "battery",
                summarize: false
            }
        }

        try {
            const response = await axios.get(config.url, config);
            return response.data
        } catch (error) {
            throw error;
        }

    }

    const getImbalance = async(serialNr, date) => {

        const credentials = btoa(`${process.env.REACT_APP_DATA_SERVICE_USERNAME}:${process.env.REACT_APP_DATA_SERVICE_PASSWORD}`);

        const tommorow = addDays(date, 1);

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: baseUrl + '/api/historic/policy/' + serialNr,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${credentials}`
            },
            params: {
                timezone: 'Europe/Brussels',
                start_date: format(date, 'yyyy-MM-dd'),
                end_date: format(tommorow, 'yyyy-MM-dd'),
                element: "yuso",
                summarize: false
            }
        }

        try {
            const response = await axios.get(config.url, config);
            return response.data
        } catch (error) {
            throw error;
        }

    }

    return {
        getPolicys,
        getImbalance,
    }

}

export default Policy