import './contactPage.css'

import FirnLogoHorBlack from '../../../Images/firn-hor-white 1.png';
import AddressIcon from '../../../Images/adressIcon.png';
import PhoneIcon from '../../../Images/phoneIcon.png';
import EmailIcon from '../../../Images/emailIcon.png';
import FrankImage from '../../../Images/frankPicture.png';
import NicoImage from '../../../Images/nicoPicture.png';
import FooterLogo from "../../../Images/firn-hor-white 1.png"
import {useNavigate} from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscAccount } from "react-icons/vsc";

import { useAuth0} from "@auth0/auth0-react"
import { useState } from 'react';

const ContactPage = () => {

    const {loginWithRedirect} = useAuth0();

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };
    
    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    

    return (
        <div className='homepage__contact__container'>
            <div className="HomePage-Navbar-contact">
                <div className="HomePage-Navbar-Logo"><img className="HomePage-Navbar-Logo-img" src={FirnLogoHorBlack} alt="FirnLogoWhiteHor" /></div>
                <div className="HomePage-Navbar-Item-Container-Desktop">
                    <div className="HomePage-Navbar-Item HomePage-Navbar-Cursor-Pointer"><a style={{color: '#FFFFFF'}} href="/">{'Home'}</a></div>
                    <div className="HomePage-Navbar-Item HomePage-Navbar-Cursor-Pointer"><a style={{color: '#FFFFFF'}} href="/curtailment">{'Curtailment'}</a></div>
                    <div className="HomePage-Navbar-Item HomePage-Navbar-Cursor-Pointer"><a style={{color: '#FFFFFF'}} href="/">{'Plans'}</a></div>
                    <div className="HomePage-Navbar-Item HomePage-Navbar-Cursor-Pointer"><a style={{color: '#FFFFFF'}} href="/contact">{'Contact'}</a></div>
                    <div className="HomePage-Navbar-Items-Dashboard HomePage-Navbar-Cursor-Pointer">
                        <div className="HomePage-Navbar-Items-Dashboard-Button">
                            <div onClick={() => loginWithRedirect({
                                appState: {
                                    returnTo: "/HomePage"
                                }
                            })} className="HomePage-Navbar-Items-Dashboard-Text">Dashboard</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="HomePage-Navbar-Item-Container-Phone-contact">
                <div className="HomePage-Navbar-Logo"><img className="HomePage-Navbar-Logo-img" src={FirnLogoHorBlack} alt="FirnLogoWhiteHor" /></div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div onClick={() => loginWithRedirect({ appState: { returnTo: "/HomePage"}})} style={{paddingRight:"20px"}}><VscAccount style={{width:"30px", height:"30px", verticalAlign: 'middle'}}/></div>
                    <div style={{paddingRight:"20px"}}>
                        <GiHamburgerMenu onClick={toggleMenu} style={{width:"30px", height:"30px", verticalAlign: 'middle'}}/>
                        {isOpen && (
                            <div
                                className="HomePage-Burger-Menu-DropDown"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="HomePage-Burger-Menu-DropDown-Item" onClick={() => {
                                    setIsOpen(false);
                                }}><a style={{color:"black"}} href="/">{'Home'}</a></div>
                                <div className="HomePage-Burger-Menu-DropDown-Item" onClick={() => {
                                    setIsOpen(false);
                                }}><a style={{color:"black"}} href="/contact">{"Contact"}</a></div>
                                <div className="HomePage-Burger-Menu-DropDown-Item" onClick={() => {
                                    setIsOpen(false);
                                }}><a style={{color:"black"}} href="/curtailment">{'Curtailment'}</a></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='homepage__contact__title'>Let's save <span className='homepage__contact__title__energy'>energy</span> together</div>
            <div className='homepage__contact__contactus__container'>
                <div className='homepage__contact__contactus__desc'>Have any questions about our product or platform? We're here to help!</div>
            </div>
            <div className='homepage__contact__info__container'>
                <div className='homepage__contact__info__item__container'>
                    <div className='homepage__contact__info__item__title'>Visit us <img width={30} src={AddressIcon} alt="addressIcon" /></div>
                    <div className='homepage__contact__info__item__desc'>Parkstraat 23</div>
                    <div className='homepage__contact__info__item__desc'>8930 Menen</div>
                </div>
                <div style={{padding: '10px 10px'}} className='homepage__contact__info__item__container'>
                    <div className='homepage__contact__info__item__title'>Write us <img width={30} src={EmailIcon} alt="addressIcon" /></div>
                    <div className='homepage__contact__info__item__desc'>sales@firnenergy.com</div>
                </div>
                <div className='homepage__contact__info__item__container'>
                    <div className='homepage__contact__info__item__title'>Call us <img width={30} src={PhoneIcon} alt="addressIcon" /></div>
                    <div className='homepage__contact__info__item__desc'>+32 56 19 88 77</div>
                </div>
            </div>
            <div className='homepage__contact__founder__container'>
                <div className='homepage__contact__founder__title'>Let's hear from our <span className='homepage__contact__title__energy'>Founders</span></div>
                <div className='homepage__contact__founder__item__container'>
                    <img style={{borderRadius: 10}} width={150} src={FrankImage} alt="FrankImage" />
                    <div className='homepage__contact__founder__item'>
                        <div className='homepage__contact__founder__item__name'>Frank Degezelle</div>
                        <div className='homepage__contact__founder__item__desc'>"Unlock the full potential of your solar installation with FIRN-your key to maximizing energy output."</div>
                    </div>
                </div>
                <div style={{justifySelf: 'flex-end'}} className='homepage__contact__founder__item__container'>
                    <div className='homepage__contact__founder__item'>
                        <div style={{textAlign: "end"}} className='homepage__contact__founder__item__name'>Nico Ramacker</div>
                        <div style={{textAlign: "end"}} className='homepage__contact__founder__item__desc__bot'>"Maximizing our energy efficiency isn't just a choice-it's a necessity in today's world."</div>
                    </div>
                    <img style={{borderRadius: 10}} width={150} src={NicoImage} alt="FrankImage" />
                </div>
            </div>
            <div className='homepage__contact__founder__container__phone'>
                <div className='homepage__contact__founder__title'>Let's hear from our <span className='homepage__contact__title__energy'>Founders</span></div>
                <div className='homepage__contact__founder__item__container'>
                    <img style={{borderRadius: 10}} width={150} src={FrankImage} alt="FrankImage" />
                    <div className='homepage__contact__founder__item'>
                        <div className='homepage__contact__founder__item__name'>Frank Degezelle</div>
                        <div className='homepage__contact__founder__item__desc'>"Unlock the full potential of your solar installation with FIRN-your key to maximizing energy output."</div>
                    </div>
                </div>
                <div style={{marginBottom: 0}} className='homepage__contact__founder__item__container'>
                    <img style={{borderRadius: 10}} width={150} src={NicoImage} alt="NicoImage" />
                    <div className='homepage__contact__founder__item'>
                        <div className='homepage__contact__founder__item__name'>Nico Ramacker</div>
                        <div className='homepage__contact__founder__item__desc'>"Maximizing our energy efficiency isn't just a choice-it's a necessity in today's world."</div>
                    </div>
                </div>
            </div>
            <div className="HomePage-Footer" id="HomePage-Contanct-Id">
                <div className="HomePage-Footer-Logo"><img width={"75%"} src={FooterLogo} alt="" /></div>
                <div className="HomePage-Footer-Dashboard">
                    <div className="HomePage-Footer-Dashboard-Titel">Dashboard</div>
                    <div style={{fontWeight: 400}} className="HomePage-Footer-Dashboard-Desc">Log in to gain access to your personal dashboard and control your energy</div>
                    <div style={{fontWeight: 400}} className="HomePage-Footer-Dashboard-Btn" onClick={() => loginWithRedirect({
                            appState: {
                                returnTo: "/HomePage"
                            }
                        })}>Log in
                    </div>
                </div>
                <div className="HomePage-Footer-Details">
                    <div>
                        <div className="HomePage-Footer-Dashboard-Titel">Sectors</div>
                        <div className="HomePage-Footer-Dashboard-Desc" style={{cursor: 'pointer', fontWeight: 400}} onClick={() => navigate("/Industrie")}>Industrial</div>
                        <div className="HomePage-Footer-Dashboard-Desc" style={{cursor: 'pointer', fontWeight: 400}} onClick={() => navigate("/Residentieel")}>Residential</div>
                        <div className="HomePage-Footer-Dashboard-Desc" style={{cursor: 'pointer', fontWeight: 400}} onClick={() => navigate("/Landbouw")}>Agriculture</div>
                        <div className="HomePage-Footer-Dashboard-Desc" style={{cursor: 'pointer', fontWeight: 400}} onClick={() => navigate("/Horeca")}>Hospitality</div>
                    </div>
                    <div>
                        <div className="HomePage-Footer-Dashboard-Titel">Contact</div>
                        <div style={{fontWeight: 400}} className="HomePage-Footer-Dashboard-Desc">Parkstraat 23</div>
                        <div style={{fontWeight: 400}} className="HomePage-Footer-Dashboard-Desc">8930 Menen</div>
                        <div style={{fontWeight: 400}} className="HomePage-Footer-Dashboard-Desc">+32 56 19 88 77</div>
                        <div style={{fontWeight: 400}} className="HomePage-Footer-Dashboard-Desc">sales@firnenergy.com</div>
                    </div>
                    <div>
                    <div className="HomePage-Footer-Dashboard-Titel">Terms & conditions</div>
                        <div style={{fontWeight: 400}} className="HomePage-Footer-Dashboard-Desc Terms"><a href="../../pdf/FIRN energy - Algemene voorwaarden.pdf" download="FIRN energy - Algemene voorwaarden.pdf">General conditions</a></div>
                        <div style={{fontWeight: 400}} className="HomePage-Footer-Dashboard-Desc Terms"><a href="../../pdf/FIRN energy - Algemene voorwaarden inzake aankoop goederen en hardware.pdf" download="FIRN energy - Algemene voorwaarden inzake aankoop goederen en hardware.pdf">Purchase goods & hardware</a></div>
                    </div>
                </div>
            </div>
        </div>  
    )
}

export default ContactPage