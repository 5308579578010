import './dashboardOverview.css'
import CurrentFlow from '../../body/currentFlow/currentFlow'
import Measurement from '../../../api/measurements'
import { useState, useEffect, useContext } from 'react'
import { DarkModeContext } from '../../../context/DarkModeContext'
import QuickView from '../../body/QuickViewDetails/QuickView';

import LineUp from '../../../Images/Line 30.png'
import Sunny from '../../../Images/Ellipse 30.png'
import ExportArrow from '../../../Images/exportarrow.png'
import ImportArrow from '../../../Images/importarrow.png'
import DashboardFlows from './Flows/dashboardFlows'
import BatteryStats from './BatteryStats/batteryStats'
import DashboardRelais from './Relay/dashboardRelais'
import GraphContainer from './Graph/graphContainer'
import DashboardSavings from './Savings/dashboardSavings'

import FlowValues from '../../../api/flowValues'
import Charts from '../../body/charts/charts'



const DashboardOverview = ({ ToggleLoading, serial_Nr }) => {

    
    const {darkMode} = useContext(DarkModeContext);
    const flowApi = FlowValues();
    const measurementApi = Measurement();
    const [screenWidth, setScreenWidth] = useState();
    const [serialNr, setSerialNr] = useState("");
    const [currentFlowData, setCurrentFlowData] = useState({
        SolarW: 0,
        GridW: 0,
        BatteryW: 0,
        HomeW: 0,
        BatteryP: 0,
    })

    useEffect(() => {
        setSerialNr(serial_Nr)
        setScreenWidth(window.innerWidth)
    }, [serial_Nr])

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const [batteryCapacity, setBatteryCapacity] = useState(20);
    const [batteryState, setBatteryState] = useState("Charging");

    //flow data for new dashboard
    const getFlowData = async() => {
        try {
            const data = await flowApi.getFlowValues(serialNr);
        
            if (data) {
                setCurrentFlowData({
                    SolarW: data.pv_power.value,
                    GridW: data.grid_power.value,
                    BatteryW: -data.battery_power.value,
                    HomeW: data.consumer_power.value,
                    BatteryP: Math.ceil(data.battery_soc.value * 100)
                })
            } else {
                setCurrentFlowData({
                    SolarW: 0,
                    GridW: 0,
                    BatteryW: 0,
                    HomeW: 0,
                    BatteryP: 0
                })
            }
        } catch (error) {
            console.log(error)

            setCurrentFlowData({
                SolarW: 1,
                GridW: 1,
                BatteryW: 1,
                HomeW: 1,
                BatteryP: 1
            })
        }
        
    }

    useEffect(() => {

        if (serialNr && (serialNr == "OM1230828TKTF13271A" || serialNr == "MENEN_FIRN")) {
            getFlowData();
        }

    }, [serialNr])


    //flowData for old dashboard
    useEffect(() => {
        if (serialNr != "OM1230828TKTF13271A" && serialNr != "MENEN_FIRN") {
            const temp = () => {
            
                const date = new Date();
    
                const GetCurrentFlowDataTemp = async() => {
                    
                    await GetCurrentFlowData(date);
                    
                }
                GetCurrentFlowDataTemp();
                
            }
            temp();
    
            const intervalId = setInterval(temp, 60 * 1000);
            
            return () => clearInterval(intervalId);
        }

    }, [serialNr])

    const GetCurrentFlowData = async (date) => {
        ToggleLoading(true)
        //get serialId from metadata1
        if (serialNr != "") {

            try {

                const data = await measurementApi.getCurrentFlowData(date, serialNr);
                const BatteryData = await measurementApi.getBatteryPercentage(date, serialNr);
    
                const CurrentFlowDataReformatted = {
                    GridW: Math.round(data[0]),
                    HomeW: Math.round(data[1]),
                    SolarW: Math.round(data[2]),
                    BatteryW: Math.round(data[3]),
                    BatteryP: BatteryData,
                }
    
                setCurrentFlowData(CurrentFlowDataReformatted)


            } catch (error) {
                setCurrentFlowData({
                    SolarW: 0,
                    GridW: 0,
                    BatteryW: 0,
                    HomeW: 0,
                    BatteryP: 0,
                })
            }

        };
        ToggleLoading(false)
    }

    const [quickViewData, setQuickViewData] = useState({
        YieldToday: 0,
        ConsumptionToday: 0,
        RevenueToday: 0,
        foundFlowData: false
    })

    // QuickViewData
    useEffect(() => {
        ToggleLoading(true)
        const temp = () => {
            const date = new Date();

            const GetQuickViewDataTemp = async() => {

                await GetQuickViewData(date);
            }
            GetQuickViewDataTemp();
        }
        temp();
    
        const QuickintervalId = setInterval(temp, 15 * 60 * 1000);
        ToggleLoading(false)
        return () => clearInterval(QuickintervalId);
        

    }, [serialNr])

    
    // QuickViewData
    const GetQuickViewData = async (date) => {

        ToggleLoading(true)
        if (serialNr != "" && serialNr != "OM1230828TKTF13271A" && serialNr != "MENEN_FIRN") {
            try {

                const QuickViewdata = await measurementApi.getQuickViewData(date, serialNr);

                const QuickViewDataValues = QuickViewdata["series"][0].values[0]
        
                const quickViewDataReformatted = {
                    YieldToday: (QuickViewDataValues[1] / 1000).toFixed(1),
                    ConsumptionToday: (QuickViewDataValues[2] / 1000).toFixed(1),
                    RevenueToday: 0,
                    foundFlowData: true
                }
    
                setQuickViewData(quickViewDataReformatted);

            } catch (error) {
                setQuickViewData([])
            }
            
        }
        ToggleLoading(false)
    }

    const [barChartData, setBarChartData] = useState();

    // BarChartData
    useEffect(() => {
        
        if (serialNr != "OM1230828TKTF13271A" && serialNr != "MENEN_FIRN") {
            const temp = () => {
                const date = new Date();
    
                const GetBarChartDataTemp = async() => {
                    ToggleLoading(true)
    
                    await GetBarChartData(date);
    
                    ToggleLoading(false)
                }
                GetBarChartDataTemp();
            }
            temp();
    
            const BARintervalId = setInterval(temp, 15 * 60 * 1000);
            ToggleLoading(false)
            return () => clearInterval(BARintervalId);
        }
        
    }, [serialNr]);

    const GetBarChartData = async (date) => {
        if (serialNr != "") {
            const result = await measurementApi.getBarChartData(date, serialNr);
            
            setBarChartData(result);
        }
    }

    const [isAdmin, setIsAdmin] = useState(false)

    const requestBarChartData = async (dataToRequest, date=new Date()) => {

        if (dataToRequest === "BarChartConsumptionData") {
            const result = await measurementApi.getBarChartData(date, serialNr);
            
            setBarChartData(result);
        } else if (dataToRequest === "BarChartBatteryData") {
            const result = await measurementApi.getBatteryPercentageRange(date, serialNr);
            
            setBarChartData(result);
        } else if (dataToRequest === "BarChartPolicy") {
            const result = await measurementApi.getPolicys(date, serialNr);


            if (result && result != []) {
                let prevHour = 0;
                let powerSetPoints = 0;
                const newResults = []
                let count = 0

                for (let i = 0; i < result.time.length; i++) {

                    const time = result.time[i];
    
                    const date = new Date(time)
                    let currentHour = date.getHours();
                    
                    if (currentHour != prevHour) {
    
                        let average = 0
                        if (powerSetPoints != 0) {
                            average = (powerSetPoints / count).toFixed(2);
                        }
                        
                        newResults.push({name: `${prevHour}u`, powerSetPoint: average})

                        prevHour = currentHour
                        powerSetPoints = 0
                        count = 0
                        if (result['policy'][i] == 2) {
                            powerSetPoints += result["powerSetpoint_W"][i]
                            count += 1;
                        }
                       
                    } else {
                        if (result['policy'][i] == 2) {
                            powerSetPoints += result["powerSetpoint_W"][i]
                            count += 1;
                        }
                    }
                }

                let average = 0
                if (powerSetPoints != 0) {
                    average = (powerSetPoints / count).toFixed(2);
                }

                newResults.push({name: `${prevHour + 1}u`, powerSetPoint: average})

                setBarChartData(newResults)

            } else {
                setBarChartData(null);
            }
        } else if (dataToRequest === "BarChartYuso") {
            
            const result = await measurementApi.getYusoSignal(date, serialNr);
            console.log(result)

            if (result[0].series) {
                const data = result[0].series[0].values;

                const arrangedData = [];
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    const datobj = new Date(element[0])

                    arrangedData.push({"Time": datobj.getHours(), "value": element[1]})
                }

                setBarChartData(arrangedData)
            } else {
                setBarChartData(null)
            }
        }
    }



    return (
        <div style={darkMode ? {backgroundColor:"#121212"} : {backgroundColor:"#D9D9D9", color: 'black'}} className='Dashboard-Body-Overview-Container'>
            
            {serialNr == "OM1230828TKTF13271A" || serialNr == "MENEN_FIRN" ?
                <>
                {/* New Dashboard for Guy */}
                <div className='Dashboard-Body-Overview-Rows'>
                    <div className='Dashboard-Body-Overview-Row'>
                        <div className='Dashboard-Body-Overview-Row-Element-Container Flows' style={{minWidth:"400px", maxWidth:"400px", minHeight: "400px", backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)' }}>
                            <DashboardFlows data={currentFlowData}/>
                        </div>
                        {/* <div style={{display: screenWidth > 1600 ? 'flex' : 'none' , backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}} className='Dashboard-Body-Overview-Row-Element-Container Extra'>
                            <DashboardRelais Relais1={1000} Relais2={0}/>
                        </div> */}
                        <div className='Dashboard-Body-Overview-Row-Element-Container Profit' style={{backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}}>
                            <DashboardSavings />
                        </div>
                        <div className='Dashboard-Body-Overview-Row-Element-Container Battery' style={{backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}}>
                            <BatteryStats serialNr={serialNr} batteryPercentage={currentFlowData.BatteryP} batteryCapacity={batteryCapacity} batteryState={batteryState}/>
                        </div>
                    </div>
                    <div className='Dashboard-Body-Overview-Row'>
                        <div className='Dashboard-Body-Overview-Row-Element-Container Graphs' style={{backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}}>
                            <GraphContainer serialNr={serialNr}/>
                        </div>
                    </div>
                    {/* <div className='Dashboard-Body-Overview-Row'>
                        <div style={{display: screenWidth <= 1600 ? 'flex' : 'none' , backgroundColor: darkMode ? '#232323' : '', border: darkMode ? '#2E2E2E solid 2px' : '2px solid #D9D9D9', boxShadow: darkMode ? '' : '0 0 20px rgba(0, 0, 0, 0.1)'}} className='Dashboard-Body-Overview-Row-Element-Container Extra'>
                            <DashboardRelais Relais1={1000} Relais2={0}/>
                        </div>
                    </div> */}
                </div>
                </>
                :
                <>
                    {/* Old Dashboard for all users */}
                    <div className={`Body-Container`}>
                        <div className='Body-First-Row'>
                            <div><CurrentFlow SOLARW={currentFlowData.SolarW} GRIDW={currentFlowData.GridW} BATTERYW={currentFlowData.BatteryW} HOMEW={currentFlowData.HomeW} BATTERYP={currentFlowData.BatteryP}></CurrentFlow></div>
                            <div><QuickView foundFlowData={quickViewData.foundFlowData} YieldToday={quickViewData.YieldToday} BatteryP={currentFlowData.BatteryP} ConsumptionToday={quickViewData.ConsumptionToday} RevenueToday={quickViewData.RevenueToday}/></div>
                        </div>
                        <div className='Body-Second-row'>
                            <div className='Charts-Container'><Charts Data={barChartData} darkMode={false} requestBarChartData={requestBarChartData} ToggleLoading={ToggleLoading}></Charts></div>
                            {isAdmin && <div><button onClick={() => navigate("/AdminPage")}>go back</button></div>}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default DashboardOverview