import './getDeviceId.css'
import User from '../../../../api/user';
import { useState } from 'react';
import { JSONTree } from 'react-json-tree';

const GetDeviceId = () => {

    const userApi = User();
    const [deviceInformation, setDeviceInformation] = useState("");

    const handleFormSubmit = async(event) => {
        event.preventDefault();

        const data = await userApi.FindDeviceId("0");
        setDeviceInformation(data.data);

    }

    return (
        <div className='DeviceId-Information-Container'> 
            {/* <div>
                <button className='DeviceId-FindDevice-btn'>Find device Id</button>
            </div>
            <div>
                <label>Controller Id</label>
                <input className='DeviceId-Input-Field' type="text" />
            </div> */}
            


            <h1 className='DeviceId-Title'>Find Device ID</h1>
            <form onSubmit={handleFormSubmit} id="findDeviceForm">
                <label className='DeviceId-Input-Label' for="controllerId">Controller ID:</label>
                <input className='DeviceId-Input-Field' type="text" id="controllerId" name="controllerId" required/>
                <button className='DeviceId-Input-btn' type="submit">Find Device ID</button>
            </form>
            <div id="deviceInfo">
                <h2>Device Information</h2>
                <div className='DeviceId-Information-Container'>
                    <JSONTree data={deviceInformation} />
                </div>
            </div>

        </div>

        
    )
}

export default GetDeviceId;