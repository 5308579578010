import './dashboardFlows.css'
import React, { useRef, useEffect, useState, useContext } from 'react';

import battIcon from '../../../../Images/battery_white_icon.png'
import gridIcon from '../../../../Images/Grid_White_Icon.png'
import homeIcon from '../../../../Images/Home_White_Icon.png'
import solarIcon from '../../../../Images/Solar_White_icon.png'
import questionIcon from '../../../../Images/questionIcon.png'

import battIconBlack from '../../../../Images/Battery_Icon.png'
import gridIconBlack from '../../../../Images/Grid_Icon.png'
import homeIconBlack from '../../../../Images/Home_Icon.png'
import solarIconBlack from '../../../../Images/Solar_Icon.png'
import questionIconBlack from '../../../../Images/questionMarkBlack.png'

import { DarkModeContext } from '../../../../context/DarkModeContext';

import loaderGIF from '../../../../Images/firn-embleem-animation.gif'

import FlowValues from '../../../../api/flowValues';

const DashboardFlows = ({data}) => {

    const flowApi = FlowValues();
    const {darkMode} = useContext(DarkModeContext);
    const [showInfo, setShowInfo] = useState(false);
    const [batteryPercentage, setBatteryPercentage] = useState(0);
    //flows
    const [flows, setFlows] = useState({
        SOLAR_BATT: false,
        SOLAR_CONS: false,
        SOLAR_GRID: false,
        BATT_CONS: false,
        BATT_GRID: false,
        GRID_BATT: false,
        GRID_CONS: false
    });

    const[values, setValues] = useState({
        SOLARW: 0,
        CONSW: 0,
        GRIDW: 0,
        BATTW: 0
    })

    useEffect(() => {
        if (data) {
            setValues({
                'SOLARW': data.SolarW,
                'CONSW': data.HomeW,
                'GRIDW': data.GridW,
                'BATTW': data.BatteryW
            })
            setBatteryPercentage(data.BatteryP)
        }
    }, [data])

    // PARAMETERS
    const [duration, setDuration] = useState(5)
    const [colors, setColors] = useState({
        "SOLAR": "#DABB50",
        "GRID": '#F88C8C',
        "BATT": '#65D8A0',
        "CONS": '#CE80EA',
    })
    const [lineWidth, setStrokeWidth] = useState(2)

    useEffect(() => {

        const newFlows = {
            SOLAR_BATT: false,
            SOLAR_CONS: false,
            SOLAR_GRID: false,
            BATT_CONS: false,
            BATT_GRID: false,
            GRID_BATT: false,
            GRID_CONS: false
        };

        if (values['SOLARW'] > values['CONSW']) {

            if (values['CONSW'] != 0) {
                newFlows.SOLAR_CONS = true;
            } else {
                newFlows.SOLAR_CONS = false;
            }
            newFlows.GRID_CONS = false;
            newFlows.BATT_CONS = false;

            if (values['GRIDW'] > 0) {
                newFlows.SOLAR_BATT = true;
                newFlows.SOLAR_GRID = false;
                newFlows.BATT_GRID = false;
                newFlows.BATT_CONS = false;
                newFlows.GRID_BATT = true;
            } else if (values['GRIDW'] < 0) {
                newFlows.GRID_BATT = false;
                newFlows.SOLAR_GRID = true;

                if (values['BATTW'] > 0) {
                    newFlows.BATT_GRID = true;
                    newFlows.SOLAR_BATT = false;
                } else if (values['BATTW'] < 0) {
                    newFlows.BATT_GRID = false;
                    newFlows.SOLAR_BATT = true;
                } else {
                    newFlows.BATT_GRID = false;
                    newFlows.SOLAR_BATT = false;
                }
            } else {
                newFlows.GRID_BATT = false;
                newFlows.SOLAR_GRID = false;
                newFlows.BATT_GRID = false;
                newFlows.SOLAR_BATT = true;
            }
        } else if (values['SOLARW'] < values['CONSW']) {
            if (values['SOLARW'] <= 0) {
                newFlows.SOLAR_CONS = false;
            } else {
                newFlows.SOLAR_CONS = true;
            }
            newFlows.SOLAR_BATT = false;
            newFlows.SOLAR_GRID = false;

            if (values['GRIDW'] > 0) {
                newFlows.BATT_GRID = false;
                newFlows.GRID_CONS = true;

                if (values['BATTW'] > 0) {
                    newFlows.BATT_CONS = true;
                    newFlows.GRID_BATT = false;
                } else if (values['BATTW'] < 0) {
                    newFlows.BATT_CONS = false;
                    newFlows.GRID_BATT = true;
                } else {
                    newFlows.BATT_CONS = false;
                    newFlows.GRID_BATT = false;
                }
            } else if (values['GRIDW'] < 0) {
                newFlows.GRID_BATT = false;
                newFlows.GRID_CONS = false;
                newFlows.BATT_GRID = true;
                newFlows.BATT_CONS = true;
            } else {
                newFlows.GRID_BATT = false;
                newFlows.GRID_CONS = false;
                newFlows.BATT_GRID = false;
                newFlows.BATT_CONS = true;
            }
        } else {

            newFlows.SOLAR_BATT = false;
            newFlows.SOLAR_CONS = true;
            newFlows.SOLAR_GRID = false;
            newFlows.GRID_CONS = false;
            newFlows.BATT_CONS = false;

            if (values['GRIDW'] > 0) {
                newFlows.GRID_BATT = true;
                newFlows.BATT_GRID = false;
            } else if (values['GRIDW'] < 0) {
                newFlows.GRID_BATT = false;
                newFlows.BATT_GRID = true;
            } else {
                newFlows.GRID_BATT = false;
                newFlows.BATT_GRID = false;
            }
        }

        setFlows(newFlows);
    }, [values])

    const toggleInfo = () => {
        setShowInfo(!showInfo);
    }

    return (
        <>
        {values.CONSW == 0 && values.BATTW == 0 && values.GRIDW == 0 && values.SOLARW == 0 ?
           <img className='loading-gif' src={loaderGIF} alt="" />
        :    
            <>
            {values.CONSW == 1 && values.BATTW == 1 && values.GRIDW == 1 && values.SOLARW == 1 ? 
                <>
                    <div>Error Rendering Flows contact customer service</div>
                </>
            :
                <svg height={375} width={375}>
                    <defs>
                        <linearGradient id="SolarToGridGradient"
                                        x1="0" y1="0" x2="1" y2="1">
                            <stop offset="0%" stopColor={colors["SOLAR"]}/>
                            <stop offset="100%" stopColor={colors["GRID"]}/>
                        </linearGradient>

                        <linearGradient id="ConsToBattGradient"
                                        x1="0" y1="1" x2="1" y2="0">
                            <stop offset="0%" stopColor={colors["BATT"]}/>
                            <stop offset="100%" stopColor={colors["CONS"]}/>
                        </linearGradient>

                        <linearGradient id="SolarToBattGradient"
                                        x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor={colors["SOLAR"]}/>
                            <stop offset="100%" stopColor={colors["BATT"]}/>
                        </linearGradient>

                        <linearGradient id="SolarToConsGradient"
                                        x1="0" y1="0" x2="1" y2="0">
                            <stop offset="0%" stopColor={colors["SOLAR"]}/>
                            <stop offset="100%" stopColor={colors["CONS"]}/>
                        </linearGradient>

                        <linearGradient id="GridToConsGradient"
                                        x1="0" y1="1" x2="0" y2="0">
                            <stop offset="0%" stopColor={colors["GRID"]}/>
                            <stop offset="100%" stopColor={colors["CONS"]}/>
                        </linearGradient>

                        <linearGradient id="BattToGridGradient"
                                        x1="0" y1="0" x2="1" y2="0">
                            <stop offset="0%" stopColor={colors["BATT"]}/>
                            <stop offset="100%" stopColor={colors["GRID"]}/>
                        </linearGradient>

                    </defs>

                    {/* SOLAR TO GRID */}
                    {flows.SOLAR_GRID && 
                        <g>
                            <path d="M105 105 L270 270" style={{fill:'none', stroke:'url(#SolarToGridGradient)', strokeWidth:lineWidth}}></path>
                            <circle r="5" fill='yellow'>
                                <animateMotion path='M107.5,107.5 267.5,267.5' dur={duration} repeatCount="indefinite"/>
                                <animate attributeName="fill" values={colors["SOLAR"] + ";" + colors["GRID"]} dur={duration} repeatCount="indefinite" />
                            </circle>
                        </g>
                    }

                    {/* CONS TO BATT */}
                    {flows.BATT_CONS && 
                        <g>
                        <path d="M270 105 L105 270" style={{fill:'none', stroke:'url(#ConsToBattGradient)', strokeWidth:lineWidth}}></path>
                            <circle r="5" fill='yellow'>
                                <animateMotion path='M107.5,267.5 267.5,107.5' dur={duration} repeatCount="indefinite"/>
                                <animate attributeName="fill" values={colors["BATT"] + ";" + colors["CONS"]} dur={duration} repeatCount="indefinite" />
                            </circle> 
                        </g>
                    }
                    
                    {/* SOLAR TO CONS */}
                    {flows.SOLAR_CONS && 
                        <g>
                            <path d="M110 100 L177.5 167.5 Q 187.5 177.5 197.5 167.5 L265 100" style={{fill:'none', stroke:'url(#SolarToConsGradient)', strokeWidth:lineWidth}}></path>
                            <circle r="5" fill='yellow'>
                                <animateMotion path='M112.5,102.5 177.5,167.5 Q 187.5,177.5 197.5,167.5 L262.5,102.5' dur={duration} repeatCount="indefinite"/>
                                <animate attributeName="fill" values={colors["SOLAR"] + ";" + colors["CONS"]} dur={duration} repeatCount="indefinite" />
                            </circle>
                        </g>
                    }

                    {/* SOLAR TO BATT */}
                    {flows.SOLAR_BATT &&
                        <g>
                            <path d="M100 110 L167.5 177.5 Q 177.5 187.5 167.5 197.5 L100 265" style={{fill:'none', stroke:'url(#SolarToBattGradient)', strokeWidth:lineWidth}}></path>
                            <circle r="5" fill='yellow'>
                                <animateMotion path='M102.5,112.5 L167.5,177.5 Q 177.5,187.5 167.5,197.5 L102.5,262.5' dur={duration} repeatCount="indefinite"/>
                                <animate attributeName="fill" values={colors["SOLAR"] + ";" + colors["BATT"]} dur={duration} repeatCount="indefinite" />
                            </circle>
                        </g>
                    }

                    {/* GRID TO CONS */}
                    {flows.GRID_CONS &&
                        <g>
                            <path d="M275 265 L207.5 197.5 Q 197.5 187.5 207.5 177.5 L275 110" style={{fill:'none', stroke:'url(#GridToConsGradient)', strokeWidth:lineWidth}}></path>
                            <circle r="5" fill='yellow'>
                                <animateMotion path='M272.5,262.5 L207.5,197.5 Q 197.5,187.5 207.5,177.5 L272.5,112.5' dur={duration} repeatCount="indefinite"/>
                                <animate attributeName="fill" values={colors["GRID"] + ";" + colors["CONS"]}  dur={duration} repeatCount="indefinite" />
                            </circle>
                        </g>
                    }

                    {/* GRID TO BATT */}
                    {flows.GRID_BATT && 
                        <g>
                            <path d="M265 275 L197.5 207.5 Q 187.5 197.5 177.5 207.5 L110 275" style={{fill:'none', stroke:'url(#BattToGridGradient)', strokeWidth:lineWidth}}></path>
                            <circle r="5" fill='yellow'>
                                <animateMotion path='M262.5,272.5 L197.5,207.5 Q 187.5,197.5 177.5,207.5 L112.5,272.5' dur={duration} repeatCount="indefinite"/>
                                <animate attributeName="fill" values={colors["GRID"] + ";" + colors["BATT"]} dur={duration} repeatCount="indefinite" />
                            </circle>
                        </g>
                    }
                    
                    {/* BATT TO GRID */}
                    {flows.BATT_GRID && 
                        <g>
                            <path d="M265 275 L197.5 207.5 Q 187.5 197.5 177.5 207.5 L110 275" style={{fill:'none', stroke:'url(#BattToGridGradient)', strokeWidth:lineWidth}}></path>
                            <circle r="5" fill='blue'>
                                <animateMotion path='M112.5,272.5 L177.5,207.5 Q 187.5,197.5 197.5,207.5 L262.5,272.5' dur={duration} repeatCount="indefinite"/>
                                <animate attributeName="fill" values={colors["BATT"] + ";" + colors["GRID"]} dur={duration} repeatCount="indefinite" />
                            </circle>
                        </g>
                    }

                    {/*outer circles Solar, Consumption, Grid, Battery */}
                    <circle cx="62.5" cy="62.5" r="60" stroke={colors["SOLAR"]} strokeWidth="2" fill='none'/>
                    <circle cx="312.5" cy="62.5" r="60" stroke={colors["CONS"]} strokeWidth="2" fill='none'/>
                    <circle cx="312.5" cy="312.5" r="60" stroke={colors["GRID"]} strokeWidth="2" fill='none'/>
                    <circle cx="62.5" cy="312.5" r="60" stroke={colors["BATT"]} strokeWidth="2" fill='none'/>
                    {/*inner circles Solar, Consumption, Grid, Battery */}
                    <circle cx="62.5" cy="62.5" r="55" stroke={colors["SOLAR"]} opacity={0.50} strokeWidth="2" fill='none'/>
                    <circle cx="312.5" cy="62.5" r="55" stroke={colors["CONS"]} opacity={0.50} strokeWidth="2" fill='none'/>
                    <circle cx="312.5" cy="312.5" r="55" stroke={colors["GRID"]} opacity={0.50} strokeWidth="2" fill='none'/>
                    <circle cx="62.5" cy="312.5" r="55" stroke={colors["BATT"]} opacity={0.50} strokeWidth="2" fill='none'/>

                    {showInfo && 
                        <g>
                            <text x="20" y="140" fill={darkMode ? 'white' : 'black'} opacity={0.7}>Production</text>
                            <text x="260" y="140" fill={darkMode ? 'white' : 'black'} opacity={0.7}>Consumption</text>
                            <text x="35" y="245" fill={darkMode ? 'white' : 'black'} opacity={0.7}>Battery</text>
                            <text x="295" y="245" fill={darkMode ? 'white' : 'black'} opacity={0.7}>Grid</text>
                        </g>
                    }

                    <text x={62.5} y={90} textAnchor='middle' dominantBaseline="middle" fill={darkMode ? 'white' : 'black'}>{Math.abs(values['SOLARW'])} W</text>
                    <text x={62.5} y={340} textAnchor='middle' dominantBaseline="middle" fill={darkMode ? 'white' : 'black'}>{Math.abs(values['BATTW'])} W</text>
                    <text x={312.5} y={90} textAnchor='middle' dominantBaseline="middle" fill={darkMode ? 'white' : 'black'}>{Math.abs(values['CONSW'])} W</text>
                    <text x={312.5} y={340} textAnchor='middle' dominantBaseline="middle" fill={darkMode ? 'white' : 'black'}>{Math.abs(values['GRIDW'])} W</text>

                    <text x={62.5} y={297.5} fontSize={'0.9rem'} textAnchor='middle' dominantBaseline="middle" fill={darkMode ? 'white' : 'black'}>{batteryPercentage} %</text>

                    <image y={270} x={37.5} height={50} href={darkMode ? battIcon : battIconBlack}></image>
                    <image y={275} x={292.5} height={40} href={darkMode ? gridIcon : gridIconBlack}></image>
                    <image y={26} x={295} height={40} href={darkMode ? homeIcon : homeIconBlack}></image>
                    <image y={25} x={42.5} height={40} href={darkMode ? solarIcon : solarIconBlack}></image>
                    <image style={{cursor: 'pointer'}} onClick={toggleInfo} x={357} height={20} href={darkMode ? questionIcon : questionIconBlack}></image>

                </svg>
            }
            </>
        }
    </>
    )

}

export default DashboardFlows;