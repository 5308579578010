import { useEffect, useState } from "react"

import { useAuth0 } from "@auth0/auth0-react";

import User from '../../../../../api/user'

const SettingsGeneral = () => {

    const { user } = useAuth0();

    const UserApi = User();

    const [generalData, setGeneralData] = useState({})

    const retrieveData = async() => {
        const data = await UserApi.getAuth0UserByUserId(user.sub);
        if (data.user_metadata.settings) {
            setGeneralData(data.user_metadata.settings.general)
        } else {
            setGeneralData({})
        }
        
    }

    useEffect(() => {
        retrieveData()
    }, [])

    return (
        <div className="settings__page__items">
            <div className="settings__page__item">
                <div className="settings__page__item__info">
                    <div className="settings__page__item__title">Name</div>
                    <div className="settings__page__item__desc">{generalData.name ? generalData.name : "Empty"}</div>
                </div>
                <div className="settings__page__item__edit">Edit</div>
            </div>
            <div className="settings__page__item">
                <div className="settings__page__item__info">
                    <div className="settings__page__item__title">Phone</div>
                    <div className="settings__page__item__desc">{generalData.phone ? generalData.phone : "Empty"}</div>
                </div>
                <div className="settings__page__item__edit">Edit</div>
            </div>
            <div className="settings__page__item">
                <div className="settings__page__item__info">
                    <div className="settings__page__item__title">Email</div>
                    <div className="settings__page__item__desc">{generalData.email ? generalData.email : "Empty"}</div>
                </div>
            </div>
            <div className="settings__page__item">
                <div className="settings__page__item__info">
                    <div className="settings__page__item__title">Serial nummer</div>
                    <div className="settings__page__item__desc">{generalData.serialNr ? generalData.serialNr : "Empty"}</div>
                </div>
            </div>
        </div>
    )

}

export default SettingsGeneral