import Wave from 'react-wavify'
import BatteryChart from '../Charts/batteryChart'
import { useContext, useEffect, useState } from 'react';
import { DarkModeContext } from '../../../../context/DarkModeContext';
import ImbalanceChart from '../Charts/ImbalanceChart';

import loaderGIF from '../../../../Images/firn-embleem-animation.gif'


import './batteryStats.css'

const BatteryStats = ({serialNr, batteryPercentage, batteryCapacity}) => {

    const [activity, setActivity] = useState("")
    const {darkMode} = useContext(DarkModeContext);
    const [items, setItems] = useState([
        {title: 'SOC & Planning'},
        // {title: 'Imbalance'}
    ])
    const [activeItem, setActiveItem] = useState(0)

    const TriggerActivity = (activity) => {
        setActivity(activity)
    }

    const batteryColors = [
        '#D83535',
        '#CD4935',
        '#C15E35',
        '#B67135',
        '#AB8635',
        '#A69135',
        '#A09B35',
        '#95AE35',
        '#89C435',
        '#00B26C' 
    ];

    return (
        <>
            {batteryPercentage == 0 ?
                <img style={{position: "absolute", top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} height={150} width={150} className='loading-gif' src={loaderGIF} alt="" />
            :   
            <>
                {batteryPercentage == 1 ?
                    <>
                        <div>Error Rendering Battery stats contact customer service</div>
                    </>
                    :
                    <>
                        <div className='Dashboard-Body-Overview-Row-Element-Object-Container'>
                            <div className='Dashboard-Body-Overview-Row-Element-Battery-Container'>
                                <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Top' style={{border: darkMode ? 'white solid 4px' : 'gray solid 4px'}}></div>
                                <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom' style={{border: darkMode ?  'white solid 4px' : 'gray solid 4px'}}>
                                    <Wave
                                        className='Dashboard-Body-Overview-Row-Element-Battery-Object-Filling'
                                        fill={batteryColors[Math.ceil(batteryPercentage/10) - 1]}
                                        paused={false}
                                        style={{ height: `${batteryPercentage}%` }}
                                        options={{
                                            height: 0,
                                            amplitude: 5,
                                            speed: 0.2,
                                            points: 3
                                        }}
                                    />
                                </div>
                            </div>
                            
                            <div className='Dashboard-Body-Overview-Row-Element-Battery-Container Percentage'>
                                <div style={{visibility: "hidden", border: darkMode ? 'white solid 4px' : 'black solid 4px'}} className='Dashboard-Body-Overview-Row-Element-Battery-Object-Top'></div>
                                <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom Percentage'>
                                    <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator-Container'>
                                        <div className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator' style={{backgroundColor: darkMode ? 'white' : 'gray'}}></div>
                                        <div>{`${batteryPercentage}%`}</div>
                                    </div>
                                    <div style={{height: `${batteryPercentage - 5}%`}} className='Dashboard-Body-Overview-Row-Element-Battery-Object-Bottom-Indicator-Pushback'></div>
                                </div>
                            </div>
                        </div>
                        <div className='Dashboard-Body-Overview-Row-Element-Battery-Data-Container'>
                            <div style={{visibility: 'hidden'}} className='Dashboard-Body-Overview-Row-Element-Battery-Data-Stats-Container'>
                                <div>
                                    <div>Total capacity</div>
                                    <div>Current content</div>
                                    <div>Current activity</div>
                                </div>
                                <div>
                                    <div>{batteryCapacity} kWh</div>
                                    <div>{batteryPercentage / 100 * batteryCapacity} kWh</div>
                                    <div style={{color: `${activity == "Charging" ? '#7DD835' : activity == "Discharging" ? '#D83535' : "white"}`}}>{activity}</div>
                                </div>
                            </div>
                            <div className='Dashboard-Body-Overview-Row-Element-Battery-Data-Graph-Container'>
                                <div className='dashboard__overview__Battery__box__graph__titles'>
                                    {items.map((item, i) => (
                                        <div key={i} className={`dashboard__overview__Battery__box__graph__titles__item${i === activeItem ? '--active' : ''}`} onClick={() => setActiveItem(i)}>{item.title}</div>
                                    ))}
                                </div>
                                <div className='Dashboard-Body-Overview-Row-Element-Battery-Data-Graph'>
                                    {activeItem === 0 && <BatteryChart TriggerActivity={TriggerActivity} serialNr={serialNr}/>}
                                    {/* {activeItem === 1 && <ImbalanceChart />} */}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
            }
        </>
    )

}

export default BatteryStats;