import React, { useEffect, useState } from "react";
import './charts.css'
import {
    ResponsiveContainer,
    AreaChart,
    BarChart,
    Legend,
    Bar,
    XAxis,
    YAxis,
    Area,
    Tooltip,
    CartesianGrid,
    ReferenceDot,
    Line,
    ComposedChart,
} from "recharts";
import './charts.css';
import User from '../../../api/user';

const Charts = ({Data, darkMode, requestBarChartData, ToggleLoading}) => {
    const [data, setData] = useState([]);
    const [noDataFound, setNoDataFound] = useState(false);
    const [graphUnit, setGraphUnit] = useState("kWh");
    const [showSolarProd, setShowSolarProd] = useState(true);
    const [showConsumption, setShowConsumption] = useState(true);
    const [showImportFromGrid, setShowImportFromGrid] = useState(true);
    const [showConsumedFromPv, setShowConsumedFromPv] = useState(true);
    const [showExport, setShowExport] = useState(true);
    const [axisColors, setAxisColors] = useState("black");
    const [toolTipStyle, setToolTipStyle] = useState("black");
    const [legendStyle, setLegendStyle] = useState();
    const [activeHeader, setActiveHeader] = useState("BarChartConsumptionData");
    const [ConsumptionProductionHeaderStyle, setConsumptionProductionHeaderStyle] = useState(true);
    const [BatteryStyle, setBatteryStyle] = useState(false);
    const [policyStyle, setPolicyStyle] = useState(false);
    const [yusoStyle, setYusoStyle] = useState(false);
    const [dayStyle, setDayStyle] = useState(true);
    const [monthStyle, setMonthStyle] = useState(false);
    const [yearStyle, setYearStyle] = useState(false);
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [actualDate, setActualDate] = useState(new Date());
    const [maxGraphValue, setMaxGraphValue] = useState(0);
    const [minGraphValue, setMinGraphValue] = useState(0);
    const [allowRightArrow, setAllowRightArrow] = useState(false);
    const [dayAheadData, setDayAheadData] = useState([]);
    const arr = {"SolarProd": setShowSolarProd, "Consumed": setShowConsumption, "Import": setShowImportFromGrid, "FromPv": setShowConsumedFromPv, "Export": setShowExport}

    //Updates the data if changed
    useEffect(() => {


        if (activeHeader == "BarChartConsumptionData") {
            if (Data) {

                const maxConsumption = Math.max(...Data.map(entry => parseFloat(entry["Consumption"])))
                const maxProduction = Math.max(...Data.map(entry => parseFloat(entry["Solar production"])))
                const maxImport = Math.max(...Data.map(entry => parseFloat(entry["Import from grid"])))
                const maxPvConsumption = Math.max(...Data.map(entry => parseFloat(entry["Consumed from PV"])))
                const maxExport = Math.max(...Data.map(entry => parseFloat(entry["Exported to grid"])))

                const highestMax = Math.max(maxConsumption, maxProduction, maxImport, maxPvConsumption, maxExport);

                setMaxGraphValue(highestMax);
            }

        } else if (activeHeader == "BarChartPolicy") {
            getDayAheadPriceFromApi()
        }

        // if (!Data) {
        //     setData([])
        //     setNoDataFound(true)
        // } else {
            setData(Data)
        // }

    }, [Data])

    const getDayAheadPriceFromApi = async() => {
        const userApi = User();

        const response = await userApi.getDayAheadPrice();

        console.log(actualDate)
        
        const dataWithConvertedPrice = []
        response.time.map((timestamp, i) => {
            if (timestamp.split("T")[0] == actualDate.toISOString().split("T")[0]) {

                dataWithConvertedPrice.push({Name: timestamp.split('T')[1].split(':')[0] + 'u', Price: response.values[i]})
            }
        })

        setDayAheadData(dataWithConvertedPrice)
    }
    
    //hides the bar by clicking on the legend
    const ShowOrHideData = (val) => {
        const methode = arr[val['payload'].id]
        methode(val.inactive);
    }

    //checks for darkmode
    useEffect(() => {

        darkMode ? setAxisColors("white") : setAxisColors("black");
        darkMode ? setToolTipStyle({borderRadius: "25px", color: "white", backgroundColor: "#181A18", border: "solid white 1px"}) : setToolTipStyle({borderRadius: "25px"});

        // darkMode ? setLegendStyle({color: "white"}) : setLegendStyle({color: "black"});

    }, [darkMode]);

    //checks for darkmode and applies styles
    const LegendStyle = (entry) => {

        if (entry.inactive) {   
            if (darkMode) {
                return({color: "white", opacity: "0.5"})
            } else {
                return({color: "black", opacity: "0.5"})
            }
        } else {
            if (darkMode) {
                return( {color: "white"});
            } else {
                return( {color: "black"});
            }
        }
        
    }   

    //this is the tooltip constructor
    const CustomConsumptionToolTip = ({ active, payload, label}) => {

        const colors = {"SolarProd": ["#ff8057", "#ffd17f"], "Consumed": ["#c069e4", "#6c32fb"], "Import": ["#f46286", "#ec2871"], "FromPv": ["#06d3ba", "#189cd8"], "BatteryPercent": ["#8ad7b2", "#22df83"], "FedToGrid": ["#D0783D", "#B87548"], "FirnControllerProfit": ["#020024", "#090979"]}
        const parentStyle = darkMode? {borderRadius: "25px", color: "white", backgroundColor: "#181A18", border: "solid white 2px", padding: "5px 10px 15px 15px"} : {background: "white", borderRadius: "25px", padding: "5px 10px 15px 15px", border: "black solid 2px"}
        if (active && payload && payload.length) {
            return (
                <div style={parentStyle}>
                    <p>{label}</p>
                    {payload.map((item) => <div style={{padding: "3px 0px",display: "flex", flexFlow: "row nowrap", alignItems: "center"}}><div style={{width: "15px", height: "15px", marginLeft: "-5px", marginRight: "5px", borderRadius: "50%", background: `linear-gradient(0deg, ${colors[item.id][0]}, ${colors[item.id][1]})`}}></div><div style={{width: '165px'}}>{item.dataKey}:</div><div>{item.value}</div><div>{item.unit}</div></div>)}
                </div>
            )
        }
    }

    
    useEffect(() => {

        if (dayStyle) {
            setDate(new Date().toISOString().split('T')[0]);
            setActualDate(new Date());
        } else if (monthStyle) {
            const newDate = (new Date().toISOString().split('T')[0]).split("-");
            setDate(`${newDate[0]}-${newDate[1]}`);
            setActualDate(new Date());
        } else {
            const newDate = (new Date().toISOString().split('T')[0]).split("-");
            setDate(`${newDate[0]}`);
            setActualDate(new Date());
        }

    }, [dayStyle, monthStyle, yearStyle])


    //see which header is active and activate the correct graph and request correct data
    const ConsumptionProductionClick = async () => {
        ToggleLoading(true)
        setConsumptionProductionHeaderStyle(true)
        setBatteryStyle(false)
        setPolicyStyle(false)
        setYusoStyle(false)

        

        setActiveHeader("BarChartConsumptionData")

        //request consumptiondata
        await requestBarChartData("BarChartConsumptionData", actualDate);
        ToggleLoading(false)
    }
    const BatteryClick = async () => {
        ToggleLoading(true)
        setConsumptionProductionHeaderStyle(false)
        setBatteryStyle(true)
        setPolicyStyle(false)
        setYusoStyle(false)
        
        setActiveHeader("BarChartBatteryData")

        //request battery data
        await requestBarChartData("BarChartBatteryData", actualDate);
        ToggleLoading(false)
    }
    const PolicyClick = async () => {
        ToggleLoading(true)
        setConsumptionProductionHeaderStyle(false)
        setBatteryStyle(false)
        setPolicyStyle(true)
        setYusoStyle(false)

        setActiveHeader("BarChartPolicy")
        
        //request revenue data
        await requestBarChartData("BarChartPolicy", actualDate);
        ToggleLoading(false)
    }
    const YusoClick = async () => {
        ToggleLoading(true)
        setConsumptionProductionHeaderStyle(false)
        setBatteryStyle(false)
        setPolicyStyle(false)
        setYusoStyle(true)

        setActiveHeader("BarChartYuso")
        
        await requestBarChartData("BarChartYuso", actualDate);
        ToggleLoading(false)
    }
    const DayClick = async() => {
        ToggleLoading(true)

        setDayStyle(true)
        setMonthStyle(false)
        setYearStyle(false)

        //request data for day actualdate

        if (ConsumptionProductionHeaderStyle) {
            await requestBarChartData("BarChartConsumptionData", actualDate);
        } else if (BatteryStyle) {
            await requestBarChartData("BarChartBatteryData", actualDate);
        } else {
            await requestBarChartData("BarChartRevenueData", actualDate);
        }

        

        ToggleLoading(false)
    }
    const MonthClick = () => {
        ToggleLoading(true)

        setDayStyle(false)
        setMonthStyle(true)
        setYearStyle(false)


        //request data per day actualdate

        ToggleLoading(false)
    }
    const YearClick = () => {
        ToggleLoading(true)

        setDayStyle(false)
        setMonthStyle(false)
        setYearStyle(true)

        //request data per month actualdate

        ToggleLoading(false)
    }
    const HandleBeforeClick = async() => {

        if (dayStyle) {

            const selectedDay = new Date(actualDate);
            const today = new Date();
        
            // Calculate the minimum allowed date (7 days before today)
            const minAllowedDate = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000)); // 6 days in milliseconds
        
            if (!(activeHeader == "BarChartPolicy") || !(selectedDay.getTime() < minAllowedDate.getTime())) {
            
                let newDate = actualDate;
                newDate.setDate(newDate.getDate() - 1);
    
                setDate(newDate.toISOString().split('T')[0]);
                setActualDate(newDate);
                setAllowRightArrow(true);
    
                //request data based on newDate
                try {
                    ToggleLoading(true)
    
                    await requestBarChartData(activeHeader, newDate);
    
                    ToggleLoading(false)
                } catch (e) {
                    console.log(e)
                }
            }

        } else if (monthStyle) {
            let newDate = actualDate;
            newDate.setMonth(newDate.getMonth() - 1);

            const tempDate = (newDate.toISOString().split('T')[0]).split("-");
            setDate(`${tempDate[0]}-${tempDate[1]}`);
            setActualDate(newDate);
            setAllowRightArrow(true);
        } else {
            let newDate = actualDate;
            newDate.setFullYear(newDate.getFullYear() - 1);

            const tempDate = (newDate.toISOString().split('T')[0]).split("-");
            setDate(`${tempDate[0]}`);
            setActualDate(newDate);
            setAllowRightArrow(true);
        }

    }
    const HandleAfterClick = async() => {

        if (allowRightArrow) {
            if (dayStyle) {
                const todayDate = new Date();
                let newDate = actualDate;
                newDate.setDate(newDate.getDate() + 1);
    
                setDate(newDate.toISOString().split('T')[0])
                setActualDate(newDate);

                if (newDate.getDate() === todayDate.getDate() && newDate.getMonth() === todayDate.getMonth() && newDate.getFullYear() === todayDate.getFullYear()) {
                    setAllowRightArrow(false);
                }
                try {
                    ToggleLoading(true)

                    await requestBarChartData(activeHeader, newDate);

                    ToggleLoading(false)
                } catch (e) {
                    console.log(e)
                }
                
            } else if (monthStyle) {
                const todayDate = new Date();
                let newDate = actualDate;
                newDate.setMonth(newDate.getMonth() + 1);
    
                const tempDate = (newDate.toISOString().split('T')[0]).split("-");
                setDate(`${tempDate[0]}-${tempDate[1]}`);
                setActualDate(newDate);

                if (newDate.getMonth() === todayDate.getMonth() && newDate.getFullYear() === todayDate.getFullYear()) {
                    setAllowRightArrow(false);
                }

                
            } else {
                const todayDate = new Date();
                let newDate = actualDate;
                newDate.setFullYear(newDate.getFullYear() + 1);
    
                const tempDate = (newDate.toISOString().split('T')[0]).split("-");
                setDate(`${tempDate[0]}`);
                setActualDate(newDate);

                if (newDate.getFullYear() === todayDate.getFullYear()) {
                    setAllowRightArrow(false);
                }
            }
        }

    }


    return (
        <div className="Graphs">
            <div className="Graph-Headers">
                <button className="Graph-Title" onClick={ConsumptionProductionClick}><div className={ConsumptionProductionHeaderStyle ? "activeCons" : "inactiveCons"}>Consumption & Production</div></button>
                <button className="Graph-Title" onClick={BatteryClick}><div className={BatteryStyle ? "activeBattery" : "inactiveBattery"}>Battery</div></button>
                <button className="Graph-Title" onClick={PolicyClick}><div className={policyStyle ? "activeRevenue" : "inactiveRevenue"}>Policy day-ahead</div></button>
                <button className="Graph-Title" onClick={YusoClick}><div className={yusoStyle ? "activeRevenue" : "inactiveRevenue"}>Imbalance</div></button>
                <div className="TimeFrame-Container">
                    <div className="TimePeriodButtons"><button onClick={DayClick} style={dayStyle ? {backgroundColor: "#007dff", color:"white"} : {}}>Day</button>
                    {/* <button onClick={MonthClick} style={monthStyle ? {backgroundColor: "#007dff", color:"white"} : {}} id="TimeFrame-Button-Middle">Month</button><button onClick={YearClick} style={yearStyle ? {backgroundColor: "#007dff", color:"white"} : {}}>Year</button> */}
                    </div>
                    <div className="TimeFrame-Time"><button onClick={HandleBeforeClick} className="TimeFrame-Time-Arrow Time-Frame-Time-Arrow-Left">{"<"}</button><button className="TimeFrame-Time-TimeDisplay">{date}</button><button onClick={HandleAfterClick} style={allowRightArrow ? {} : {opacity: 0.5, cursor: "default"}} className="TimeFrame-Time-Arrow Time-Frame-Time-Arrow-Right">{">"}</button></div>
                </div>
            </div>
            {/* ConsumptionAndProduction Chart */}
            {activeHeader == "BarChartConsumptionData"? 
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data}>
                    <defs>
                        <linearGradient id={`SolarProductionColor`} x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                            <stop offset='0' stopColor='#ffd17f' />
                            <stop offset='1' stopColor='#ff8057' />
                        </linearGradient>
                        <linearGradient id={`ConsumptionColor`} x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                            <stop offset='0' stopColor='#c069e4' />
                            <stop offset='1' stopColor='#6c32fb' />
                        </linearGradient>
                        <linearGradient id={`ImportFromGridColor`} x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                            <stop offset='0' stopColor='#f46286' />
                            <stop offset='1' stopColor='#ec2871' />
                        </linearGradient>
                        <linearGradient id={`ConsumedFromPV`} x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                            <stop offset='0' stopColor='#06d3ba' />
                            <stop offset='1' stopColor='#189cd8' />
                        </linearGradient>
                        <linearGradient id={`ExportToGridColor`} x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                            <stop offset='0' stopColor='#D0783D' />
                            <stop offset='1' stopColor='#B87548' />
                        </linearGradient>
                    </defs>
                        <CartesianGrid strokeDasharray="0 0" />
                        <XAxis dataKey="Name" stroke={axisColors}/>
                        <YAxis style={{fontSize:"0.9rem"}} unit={"kWh"} domain={[0, maxGraphValue + (maxGraphValue * 0.05)]} minTickGap={0} scale={"linear"} stroke={axisColors} />
                        <Tooltip contentStyle={toolTipStyle} isAnimationActive={true} content={<CustomConsumptionToolTip/>}/>
                        <Legend onClick={(value) => ShowOrHideData(value)} formatter={(value, entry, index) => <span style={LegendStyle(entry)}>{value}</span>}/>
                        <Bar id="SolarProd" dataKey="Solar production" fill={`url(#SolarProductionColor)`} legendType="circle" unit={"kWh"} hide={showSolarProd ? false : true}/>
                        <Bar id="FedToGrid" dataKey="Exported to grid" fill={`url(#ExportToGridColor)`}  legendType="circle" unit={"kWh"} hide={showExport ? false : true} />
                        <Bar id="Consumed" dataKey="Consumption" fill={`url(#ConsumptionColor)`} legendType="circle" unit={"kWh"} hide={showConsumption ? false : true}/>
                        <Bar id="Import" dataKey="Import from grid" fill={`url(#ImportFromGridColor)`} legendType="circle" unit={"kWh"} hide={showImportFromGrid ? false : true}/>
                        <Bar id="FromPv" dataKey="Consumed from PV" fill={`url(#ConsumedFromPV)`} legendType="circle" unit={"kWh"} hide={showConsumedFromPv ? false : true}/>
                    </BarChart>
                </ResponsiveContainer>
            : <></>}
            {/* BatteryChart */}
            {activeHeader == "BarChartBatteryData" ? 
                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart width={730} height={250} data={data}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8ad7b2" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#22df83" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Name" stroke={axisColors} interval={57}/>
                        <YAxis domain={[0, 100]} stroke={axisColors} unit={"%"} />
                        <Tooltip content={<CustomConsumptionToolTip/>}/>
                        <Legend iconType="plainline" />
                        <Area id="BatteryPercent" type={"monotone"} dataKey="Battery percentage" stroke="#22df83" fillOpacity={1} unit={"%"} legendType="circle" fill="url(#colorUv)"/>
                        {/* hide={showSolarProd ? false : true} */}
                    </AreaChart>
                </ResponsiveContainer>
            : <></>}
            {/* PolicyChart */}
            {activeHeader == "BarChartPolicy" && noDataFound && 
            <div>
                AI decision-making has not been activated yet.
            </div>
            }
            {activeHeader == "BarChartPolicy" && !noDataFound ? 
                <ResponsiveContainer width="110%" height={300}>
                    <ComposedChart data={data}>
                    <defs>
                        <linearGradient id={`powerSetPoint`} x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                            <stop offset='0' stopColor='#06d3ba' />
                            <stop offset='1' stopColor='#189cd8' />
                        </linearGradient>
                    </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Name" stroke={axisColors}/>
                        <YAxis yAxisId="left" orientation="left" style={{fontSize:"0.7rem"}} unit={"W"}/>
                        <YAxis yAxisId="right" orientation="right" unit={"€"} style={{fontSize:"0.7rem"}}></YAxis>
                        {/* <Tooltip contentStyle={toolTipStyle} isAnimationActive={true} content={<CustomConsumptionToolTip/>}/> */}
                        <Legend iconType="circle"/>
                        <Bar yAxisId="left" id="powerSetPoint" stackId={'a'} unit={'W'} dataKey="powerSetPoint" fill={`url(#powerSetPoint)`} />
                        <Line yAxisId="right" data={dayAheadData} dataKey="Price" stroke="#eb2d3a" dot={false} strokeWidth={2}></Line>
                    </ComposedChart>
                </ResponsiveContainer>
            : <></>}
            {/* yusochart */}
            {activeHeader == "BarChartYuso" && data == null && 
            <div>
                Unbalanced signals have not yet been activated
            </div>
            }
            {activeHeader == "BarChartYuso" && data ? 
                <ResponsiveContainer width="110%" height={300}>
                    <ComposedChart data={data}>
                    <defs>
                        <linearGradient id={`powerSetPoint`} x1='0' y1='0' x2='0' y2='100%' spreadMethod='reflect'>
                            <stop offset='0' stopColor='#06d3ba' />
                            <stop offset='1' stopColor='#189cd8' />
                        </linearGradient>
                    </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis interval={200} dataKey={"Time"} unit={"u"} stroke={axisColors}/>
                        <YAxis orientation="left" style={{fontSize:"0.7rem"}} unit={"W"}/>
                        {/* <Tooltip contentStyle={toolTipStyle} isAnimationActive={true} content={<CustomConsumptionToolTip/>}/> */}
                        <Line dataKey={"value"} stroke="#eb2d3a" dot={false} strokeWidth={2}></Line>
                    </ComposedChart>
                </ResponsiveContainer>
            : <></>}
        </div>
    )
}

// 
export default Charts;