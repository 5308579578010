import { Label, ComposedChart, CartesianGrid , Legend, CartesianAxis, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import './graphMaker.css'
import { useState } from 'react';

const GraphMaker = ({data, chartObjects, handleSelectedItemRemove}) => {

    const handleLegendClick = (event) => {
        handleSelectedItemRemove(event.dataKey);
        //look at the datakey
    }

    const [isPercentageNeeded, setIsPercentageNeeded] = useState(false);

    return (
        <ResponsiveContainer style={{fontSize: "1rem", marginTop: 20}} width={"100%"} height="85%">
            <ComposedChart data={data}>
                <XAxis unit={'u'} interval={0}/>
                <YAxis unit={'W'} width={75}/>
                <YAxis yAxisId={'Percentage'} unit={'%'} orientation='right' domain={[0, 100]} hide={isPercentageNeeded}/>
                <CartesianGrid style={{opacity: 0.5}} vertical={false}/>
                {chartObjects.map((object) => {
                    if (object.object.props.yAxisId == 'Percentage') {
                        () => setIsPercentageNeeded(true);
                    }
                    return (
                        object.object
                    )
                }  
                )}
                <Legend wrapperStyle={{ color: '#555', fontSize: '0.9rem', fontWeight: 'bold', cursor: 'pointer' }} onClick={handleLegendClick} />
            </ComposedChart>
        </ResponsiveContainer>
    );

}

export default GraphMaker