// import { Link } from "react-router-dom";

import Navbar from "../Navbar/navbar";
import Login from '../Authentication/login';
import OldWebHomePage from '../HomePage/OldWebHomePage';
import WebHomePage from '../HomePage/WebHomePage';
import AdminPage from '../body/admin/adminPage';
import Profiel from '../Profielen/Profiel';
import Dashboard from '../Dashboard/dashboard'
import SignUpPage from "../SignUpForm/signUpForm";
import ContactPage from "../HomePage/Contact-Page/contactPage";
import CurtailmentPage from "../HomePage/Curtailment-Page/curtailmentPage";

export const LoginPage = () => (

    <div>
        <Login></Login>
    </div>

);

export const HomePage = () => (

    <div>
        <Dashboard/>
    </div>

);

export const Contact_Page = () => (

    <div>
        <ContactPage/>
    </div>

);

export const Curtailment_Page = () => (

    <div>
        <CurtailmentPage/>
    </div>

);

export const WebsiteHomePage = () => (
    <div>
        <WebHomePage/>
    </div>
)

export const FormPage = () => (
    <div>
        <SignUpPage/>
    </div>
)

export const WebAdminPage = () => (
    <div>
        {/* <Navbar></Navbar> */}
        <AdminPage></AdminPage>
    </div>
)

export const ProfielPage = ( {profiel} ) => (
    <div>
       <Profiel profiel={profiel}></Profiel>
    </div>
)