import { useState } from 'react';
import './App.css';
import Navbar from './components/Navbar/navbar'
import { UserDashboard, Curtailment_Page, LoginPage, HomePage, WebsiteHomePage, WebAdminPage , ProfielPage, FormPage, Contact_Page} from './components/pages/pages'
import { Routes, Route, Router} from "react-router-dom";
import './translations/i18n';


function App() {

  return (
      <Routes>
          <Route path="/" element={<WebsiteHomePage />} />
          <Route path="/LoginPage" element={<LoginPage />} />
          <Route path="/Form" element={<FormPage />} />
          <Route path="/Contact" element={<Contact_Page />} />
          <Route path="/HomePage" element={<HomePage />} />
          <Route path="/AdminPage" element={<WebAdminPage />} />
          <Route path="/Industrie" element={<ProfielPage profiel="industrie" />} />
          <Route path="/Horeca" element={<ProfielPage profiel="horeca" />} />
          <Route path="/Residentieel" element={<ProfielPage profiel="residentieel" />} />
          <Route path="/Landbouw" element={<ProfielPage profiel="landbouw" />} />
          <Route path="/Curtailment" element={<Curtailment_Page />} />
      </Routes>
  );
}

export default App;
