import { act, useState } from 'react'
import './dashbordSettings.css'

//pages
import SettingsGeneral from './Setting-Pages/General/settingsGeneral'
import SettingsRelais from './Setting-Pages/Relais/settingsRelais'
import SettingsRelaisItem from './Setting-Pages/Relais/Relais-Item/settingsRelaisItem'
import SettingsBattery from './Setting-Pages/Battery/settingsBattery'
import SettingsChargingStation from './Setting-Pages/ChargingStation/settingsChargingStation'
import SettingsChargingStationItem from './Setting-Pages/ChargingStation/ChargingStation-Item/settingsChargingStationItem'

//api
import Settings from '../../../api/settings'

const DashbordSettings = ({serialNr}) => {

    const settingsApi = Settings();

    const [pages, setPages] = useState([
        {name: "General", content: <SettingsGeneral />},
        {name: "Relais", content: <SettingsRelais serialNr={serialNr}/>, relais: []},
        {name: "Charging station", content: <SettingsChargingStation serialNr={serialNr}/>, stations: [
            // {
            //     gateway: "firn",
            //     name: "garage",
            //     node_id: "SR_1",
            //     task: {
            //         mode: "slow_charge",
            //         endTime: 8
            //     }
            // }
        ]
        }
    ])
    const [activePage, setActivePage] = useState(0)
    const [activeRelais, setActiveRelais] = useState(-1)
    const [activeStation, setActiveStation] = useState(-1)

    const handlePageChange = (index) => {
        
        setActivePage(index)

        if (pages[index].name == "Relais") {
            getRelais();
        }
        
        setActiveRelais(-1)
        setActiveStation(-1)
    }

    const handleRelaisClick = (e, index) => {
        e.stopPropagation();
        setActiveRelais(index);
    }

    const handleStationClick = (e, index) => {
        e.stopPropagation();
        console.log(index)
        setActiveStation(index);
    }

    const getRelais = async() => {
        const data = await settingsApi.getRelais(serialNr);

        if (data.relay_switches) {
            const relays = Object.entries(data.relay_switches).map(([key, value]) => ({
                id: key,
                ...value
            }));
    
    
            setPages((prevPages) => 
                prevPages.map((page, i) => {
                    if (page.name === "Relais") {
                        return {
                            ...page,
                            relais: relays
                        };
                    }
                    return page;
                })
            );
        }
    }

    return (
        <div className='settings'>
            <div className='settings__header'>
                <div className='settings__header__title'>Settings</div>
            </div>
            <div className='settings__content'>
                <div className='settings__pages'>
                    {pages.map((page, i) => 
                        <div key={i} className={`settings__page ${i == activePage ? 'settings__page--active' : ''}`} onClick={() => handlePageChange(i)}>
                            {page.relais || page.stations ?
                                <>
                                    {page.relais ?
                                        <>
                                            <div className='settings__page__relais__header'>
                                                <div>{page.name}</div>
                                                <div className={`settings__page__relais__header__dropdown--${i == activePage ? 'active' : 'inactive'}`}></div>
                                            </div>
                                            <div className='settings__page__relais__content'>
                                                <div className='settings__page__relais__line' style={{height: `${i == activePage ? 20 + 28 * (page.relais.length - 1) : 0}px`}}>
                                                    <div className='settings__page__relais__line--active' style={{height: `${activeRelais < 0 ? '0px' : (20 + 28 * activeRelais) + 'px'}`}}></div>
                                                </div>
                                                <div style={{width: '100%'}}>
                                                    {i == activePage && page.relais.map((relais, i) => 
                                                        <div key={i} onClick={(e) => handleRelaisClick(e, i)} className={`settings__page__relais__item ${i === activeRelais ? 'active' : 'inactive'}`}>
                                                            <div className={`settings__page__relais__item__preline ${i === activeRelais ? 'active' : 'inactive'}`}></div>
                                                            <div>{relais.name}</div>
                                                        </div>
                                                    )}
                                                </div>
                                                
                                            </div>
                                        </>
                                        :
                                        <div></div>
                                    }
                                    {page.stations ?
                                        <>
                                            <div className='settings__page__relais__header'>
                                                <div>{page.name}</div>
                                                <div className={`settings__page__relais__header__dropdown--${i == activePage ? 'active' : 'inactive'}`}></div>
                                            </div>
                                            <div className='settings__page__relais__content'>
                                                <div className='settings__page__relais__line' style={{height: `${i == activePage ? 20 + 28 * (page.stations.length - 1) : 0}px`}}>
                                                    <div className='settings__page__relais__line--active' style={{height: `${activeStation < 0 ? '0px' : (20 + 28 * activeStation) + 'px'}`}}></div>
                                                </div>
                                                <div style={{width: '100%'}}>
                                                    {i == activePage && page.stations.map((station, i) => 
                                                        <div key={i} onClick={(e) => handleStationClick(e, i)} className={`settings__page__relais__item ${i === activeStation ? 'active' : 'inactive'}`}>
                                                            <div className={`settings__page__relais__item__preline ${i === activeStation ? 'active' : 'inactive'}`}></div>
                                                            <div>{station.name}</div>
                                                        </div>
                                                    )}
                                                </div>
                                                
                                            </div>
                                        </>
                                        :
                                        <div></div>
                                    }
                                </>   
                                
                            :
                                <div>{page.name}</div>
                            }
                        </div>  
                    )}
                </div>
                <div className='settings__page__content'>
                    {activePage > -1 && activeRelais == -1 && activeStation == -1 && pages[activePage].content}
                    {activeRelais > -1 && <SettingsRelaisItem serialNr={serialNr} relay={pages[activePage].relais[activeRelais]}/>}
                    {activeStation > -1 && <SettingsChargingStationItem serialNr={serialNr} station={pages[activePage].stations[activeStation]}/>}
                </div>
            </div>
        </div>
    )

}

export default DashbordSettings