import React from 'react';
import { AuthProvider } from 'react-auth-kit';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { DarkModeProvider } from './context/DarkModeContext';

createRoot(document.getElementById('root')).render(
  
  <React.StrictMode>
    <DarkModeProvider>
      <BrowserRouter basename='/'>
        <Auth0ProviderWithNavigate>
          <App />
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </DarkModeProvider>
  </React.StrictMode>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
