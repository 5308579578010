import { useEffect, useState } from 'react';
import './userDisplay.css';
import User from '../../../../api/user';
import { useNavigate } from 'react-router-dom';



const UserDisplay = ({user}) => {

    const [hasSerialNr, setHasSerialNr] = useState(false);
    const [accountType, setAccountType] = useState("")
    const [userRole, setUserRole] = useState("No Role");
    const navigate = useNavigate();

    useEffect(() => {
        console.log(user)
        if (user.app_metadata) {
            if (user.app_metadata.serial_Nr) {
                setHasSerialNr(user.app_metadata.serial_Nr)
            }
            if (user.app_metadata.type) {
                setAccountType(user.app_metadata.type)
            }
        }

        if (user.role) {
            setUserRole(user.role)
        }

    }, [])
    
    const ManageDashboardClick = () => {
        const dataToPass = { 
            serialNr: hasSerialNr,
            accountType: accountType,
            user: user
        };

        navigate("/HomePage", { state: dataToPass})
    }

    return (

        <div className='UserDisplay-Container'>
            <div className='UserDisplay-User-Flex'>
                <div><img src={user.picture} alt="" /></div>
                <div style={{fontWeight: "bold", opacity:0.6}}>{userRole}</div>
                <div style={{fontSize: "1.1rem"}}>{user.email}</div>
                <div>Login amount: {user.logins_count}</div>
                {hasSerialNr && <div onClick={ManageDashboardClick} className='UserDisplay-User-Link'>go to dashboard {"->"}</div>}
            </div>
        </div>

    )


}

export default UserDisplay;