import { useEffect, useState } from 'react';
import UsersList from './users/usersList'
import SignalDisplay from './signals/signalDisplay'
import './adminPage.css'
import { ImUsers } from "react-icons/im";
import { RiBatteryShareLine } from "react-icons/ri";
import { TbDeviceDesktopCog } from "react-icons/tb";
import { MdAccountCircle } from "react-icons/md";
import User from '../../../api/user';
import { RiLogoutBoxLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import userIconBlack from '../../../Images/6522516.png';
import PythonTest from './testPython/pythonTest';
import { useAuth0 } from '@auth0/auth0-react'
import GetDeviceId from './deviceId/getDeviceId';
import DataformOffertes from './offertes/dataformOffertes';
import loaderGIF from '../../../Images/firn-embleem-animation.gif'
import InstallerUserList from './installerUsers/installerUserList';


const AdminPage = () => {

    const { user, isAuthenticated, isLoading, logout } = useAuth0();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [activePage, setActivePage] = useState("")
    const [username, setUsername] = useState("");
    const [picture, setPicture] = useState("");
    const [role, setRole] = useState("");

    useEffect(() => {

        if (isAuthenticated) {
            // Get user roles
            const roles = user && user['https://firnenergy.com/roles'];
            
            if (roles) {
                // Check if user is admin or tempUser
                if (roles.includes("SuperAdmin") || roles.includes("Admin") || roles.includes("Installer") || roles.includes("Sales")) {
                    // Redirect admin users to AdminPage
                    setUsername(user.nickname);
                    setPicture(user.picture)
                    
                    setRole(roles[0])
                    setLoading(false)
                } else {
                    //redirect to homepage not authenticated
                    navigate("/")
                }
            }
        } else if (!isLoading) {
            navigate("/")
        }
        setLoading(false)
    }, [isLoading]);

    const ToggleLoading = (val) => {
        setLoading(val)
    }

    // const getUsername = async() => {
    //     try {
    //         const user = await userApi.GetUsername(token);

    //         let name = user.data.user.name.split('@')[0];
    //         if (name.includes('.')) {
    //             name = name.split('.')[0] + " " + name.split('.')[1]
    //         }

    //         setUsername(name);
    //     } catch (error) {
    //         //token expired or invalid navigate to Login
    //         localStorage.removeItem('jwtToken');
    //         navigate('/');
    //     }
    // }

    // getUsername()

    const SwitchPage = (SidebarItem) => {
        setActivePage(SidebarItem)
    }



    return (
        <>
            {loading ? (<div className='overlay block-interactions'><div className="loading-container"><img className='loading-gif' src={loaderGIF} alt="" /></div></div>) : <></> }
            <div className='Sidebar'>
                <div className='Sidebar-User'>
                    <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                        <img style={{borderRadius: "25px"}} width={"50px"} src={picture} alt="" />
                    </div>
                    <div>
                        <div style={{fontWeight:"bold"}}>{role}</div>
                        <span>{username}</span>
                    </div>
                </div>
                {role == "Admin" || role == "SuperAdmin" || role == "Sales" ? <div onClick={() => SwitchPage("Users")} className={activePage == "Users" ? "Sidebar-Link active" : "Sidebar-Link"}><ImUsers className='Sidebar-Icon'/><div>Users</div></div> : <div></div>}
                {role == "Admin" || role == "SuperAdmin" ? <div onClick={() => SwitchPage("Battery Signals")} className={activePage == "Battery Signals" ? "Sidebar-Link active" : "Sidebar-Link"}><RiBatteryShareLine className='Sidebar-Icon'/><div>Battery Signals</div></div> : <div></div>}
                {/* <div onClick={() => SwitchPage("Test Python")} className={activePage == "Test Python" ? "Sidebar-Link active" : "Sidebar-Link"}><RiBatteryShareLine className='Sidebar-Icon'/><div>Test Python</div></div> */}
                {role == "Admin" || role == "SuperAdmin" ? <div onClick={() => SwitchPage("Get DeviceId")} className={activePage == "Get DeviceId" ? "Sidebar-Link active" : "Sidebar-Link"}><TbDeviceDesktopCog className='Sidebar-Icon'/><div>Get DeviceId</div></div> : <div></div>}
                {role == "Admin" || role == "SuperAdmin" || role=="Installer" || role == "Sales" ? <div onClick={() => SwitchPage("Offerte")} className={activePage == "Offerte" ? "Sidebar-Link active" : "Sidebar-Link"}><TbDeviceDesktopCog className='Sidebar-Icon'/><div>Offerte</div></div> : <div></div>}
                {role == "Installer" ? <div onClick={() => SwitchPage("InstallerUsers")} className={activePage == "InstallerUsers" ? "Sidebar-Link active" : "Sidebar-Link"}><ImUsers className='Sidebar-Icon'/><div>Users</div></div> : <div></div>}
                <div onClick={() => logout()} className="Sidebar-Bottom Sidebar-Link"><RiLogoutBoxLine className='Sidebar-Icon'/><div>Logout</div></div>
            </div>

            {activePage == "Users" ? <div className='Sidebar-Adjustment'><UsersList ToggleLoading={ToggleLoading}></UsersList></div> : <div></div>}
            {activePage == "Battery Signals" ? <div className='Sidebar-Adjustment'><SignalDisplay></SignalDisplay></div> : <div></div>}
            {activePage == "Get DeviceId" ? <div className='Sidebar-Adjustment'><GetDeviceId></GetDeviceId></div> : <div></div>}
            {/* {activePage == "Offerte" ? <div className='Sidebar-Adjustment'><div className='ui segment'><DataformOffertes></DataformOffertes></div></div> : <div></div>} */}
            {activePage == "Offerte" ? <div className='Sidebar-Adjustment'><div style={{width:"99.5%", backgroundColor:"black", height:"95vh"}}><iframe src="https://offertes.firnenergy.com/" frameborder="0" width={"100%"} height={"100%"}></iframe></div></div> : <div></div>}
            {activePage == "InstallerUsers" ? <div className='Sidebar-Adjustment'><InstallerUserList ToggleLoading={ToggleLoading}></InstallerUserList></div> : <div></div>}
            {/* <iframe src="https://offertes.firnenergy.com/" frameborder="0" width={'100%'} height={'90vh'}></iframe> */}
        </>
    )
}

export default AdminPage;