import './graphContainer.css'
import GraphSelectorItem from './GraphSelector/graphSelectorItem'
import { useContext, useEffect, useState } from "react";
import GraphMaker from './GraphMaker/graphMaker';
import { Bar, Line } from 'recharts'

import { DarkModeContext } from '../../../../context/DarkModeContext';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import {Droppable} from '../../../DragAndDrop/Droppable';
import {Draggable} from '../../../DragAndDrop/Draggable';
import GraphValues from '../../../../api/graphValues';

import questionMarkBlack from '../../../../Images/questionMarkBlack.png';
import questionMark from '../../../../Images/questionIcon.png';

import Policy from '../../../../api/policy';

import { format, isToday, parse, subDays, addDays } from 'date-fns';

const GraphContainer = ({serialNr}) => {

    const [dateChangeIds, setDateChangeIds] = ([])

    //add new graph value
    //1 Add a useState
    //2 update valueDatas
    const policyApi = Policy();
    const [date, setDate] = useState(format(new Date(), 'dd-MM-yyyy'))
    const [isInfoHovered, setIsInfoHovered] = useState(false);
    const {darkMode} = useContext(DarkModeContext);
    const graphValueApi = GraphValues();

    const initialData = [
        { id: 1, name: "production", color: "#DABB50", chartObject: <Bar dataKey={'production'} fill='#DABB50' radius={[5, 5, 0, 0]}/>, requestName: "solarProduction", data: [] },
        { id: 2, name: "consumption", color: "#CE80EA", chartObject: <Bar dataKey={'consumption'} fill='#CE80EA' radius={[5, 5, 0, 0]}/>, requestName: "consumption", data: [] },
        { id: 3, name: "export to grid", color: "#F88C8C", chartObject: <Bar dataKey={'export to grid'} fill='#F88C8C' radius={[5, 5, 0, 0]}/>, requestName: "export", data: [] },
        { id: 4, name: "import from grid", color: "#46B6BD", chartObject: <Bar dataKey={'import from grid'} fill='#46B6BD' radius={[5, 5, 0, 0]}/>, requestName: "import", data: [] },
        { id: 5, name: "state of charge", color: "#65D8A0", chartObject: <Line dataKey={'state of charge'} dot={false} stroke='#65D8A0' strokeWidth={2} yAxisId={'Percentage'} legendType='plainline'/>, requestName: "soc", data: [] },
        // { id: 6, name: "policy", color: "#FFFFFF", chartObject: <Bar dataKey={'policy'} fill='#FFFFFF' radius={[5, 5, 0, 0]}/>, requestName: "policy", data: [] }
    ];

    const [items, setItems] = useState(initialData.map(item => ({id: item.id, item: item})))

    const reformatPolicyData = (data) => {
        let count = 0
        let power = 0
        let time = 0
        const newData = []

        for (let i = 0; i < 288; i++) {
            power += data.powerSetpoint_W[i]
            count += 1

            if (count == 12) {
                const newFormat = {time: `${time < 10 ? `0${time}` : time}:00:00`, value: power}
                newData.push(newFormat)
                power = 0
                count = 0
                time += 1
            }
            
        }

        return newData
    }

    useEffect(() => {
        if (serialNr) {
            const requestGraphValueData = async() => {

                const updatedItems = await Promise.all(
                    items.map(async (item) => {
                        let data = [];
                        // if (item.id == 6) {
                        //     data = await policyApi.getPolicys(serialNr, parse(date, 'dd-MM-yyyy', new Date()));

                        //     if (data) {
                        //         data = reformatPolicyData(data)
                        //     } else {
                        //         data = []
                        //     }

                        // } else {
                        data = await graphValueApi.getGraphValue(item.item.requestName, serialNr, parse(date, 'dd-MM-yyyy', new Date()));
                        // }
                        
                        return {...item, item: {
                            ...item.item, data: data
                        }};
                    })
                )
                setItems(updatedItems)
                
            }
            requestGraphValueData();
        }
    }, [serialNr, date])

    const [data, setData] = useState([
        {"time": "01:00:00"},
        {"time": "02:00:00"},
        {"time": "03:00:00"},
        {"time": "04:00:00"},
        {"time": "05:00:00"},
        {"time": "06:00:00"},
        {"time": "07:00:00"},
        {"time": "08:00:00"},
        {"time": "09:00:00"},
        {"time": "10:00:00"},
        {"time": "11:00:00"},
        {"time": "12:00:00"},
        {"time": "13:00:00"},
        {"time": "14:00:00"},
        {"time": "15:00:00"},
        {"time": "16:00:00"},
        {"time": "17:00:00"},
        {"time": "18:00:00"},
        {"time": "19:00:00"},
        {"time": "20:00:00"},
        {"time": "21:00:00"},
        {"time": "22:00:00"},
        {"time": "23:00:00"},
        {"time": "00:00:00"}
    ])

    const [chartObjects, setChartObjects] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);
    const [activeItem, setActiveItem] = useState(null);

    function handleMouseEnter() {
        setIsInfoHovered(true)
    }

    function handleMouseLeave() {
        setIsInfoHovered(false)
    }

    function handleDragStart(event) {
        setActiveItem(items.find((element) => element.id === event.active.id));
    }

    function handleDragEnd(event) { 

        if (event.over && event.over.id == "droppable") {

            const newItem = items.find((element) => element.id === event.active.id);
            const newItemData = newItem.item.data
            if (newItemData.length !== 0) {
                
                const newData = data.map((entry, i) => ({
                    ...entry,
                    [newItem.item.name] : newItemData[i] == undefined || newItemData[i].value == null  ? null : newItemData[i].value
                }))

                setData(newData)

                const newChartObject = {'id': newItem.id, 'object': newItem.item.chartObject}
                setChartObjects((prevChartObjects) => [
                    ...prevChartObjects,
                    newChartObject
                ])

                setSelectedItems((prevSelectedItems) => [
                    ...prevSelectedItems,
                    activeItem
                ]);
                setItems((prevItems) => 
                    prevItems.filter((item) => item.id !== activeItem.id)
                );
            }
        }

        setActiveItem(null)
    } 

    const handleSelectedItemRemove = (name) => {

        const foundItem = selectedItems.find((element) => element.item.name == name);

        setItems((prevItems) => {
            const updatedItems = [...prevItems, foundItem];

            return updatedItems.sort((a, b) => a.id - b.id);
        })
        setSelectedItems((prevSelectedItems) => 
            prevSelectedItems.filter((item) => item.id !== foundItem.id)
        );
        setChartObjects((prevChartObjects) =>
            prevChartObjects.filter((item) => item.id !== foundItem.id)
        );
    }

    const handleDateBackClick = () => {
        setItems(initialData.map(item => ({id: item.id, item: item})))

        // console.log(selectedItems, "selected item")
        // console.log(chartObjects)

        // const id = selectedItems.map((item) => (item.id))
        // setDateChangeIds(id)
        //we save the charts that need to be displayed

        setChartObjects([])
        setSelectedItems([])

        const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
        const dateBefore = subDays(parsedDate, 1);
        //set data empty so loading triggers?
        setDate(format(dateBefore, 'dd-MM-yyyy'))
    }

    const handleDateForwardClick = () => {
        const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
        //set data empty so loading triggers?
        //reset items list?
        if (!isToday(parsedDate)) {
            setItems(initialData.map(item => ({id: item.id, item: item})))
            setChartObjects([])
            setSelectedItems([])

            const dateAfter = addDays(parsedDate, 1);
            setDate(format(dateAfter, 'dd-MM-yyyy'));
        }
    }


    return (

        
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <div className='GraphContainer'>
                <div className={`GraphSelector ${activeItem ? 'no-scroll' : ''} ${isInfoHovered ? `${darkMode? 'infoHoveredDarkmode' : 'infoHovered'}`: ''}`} style={{border: darkMode ?  '#3a3a3a solid 2px' : '#CBCBCB solid 2px'}}>
                    {items.map(item => 
                        <Draggable key={item.id} id={item.id}>
                            <GraphSelectorItem 
                                Name={item.item.name} 
                                Color={item.item.color} 
                                Data={item.item.data} 
                                GradientId={item.item.id}
                            />
                        </Draggable>
                    )}
                </div>

                <DragOverlay>
                    {activeItem ? (
                        <GraphSelectorItem 
                            Name={activeItem['item'].name} 
                            Color={activeItem['item'].color} 
                            Data={activeItem["item"].data} 
                            GradientId={activeItem['item'].id}
                    />
                    ) : null}
                </DragOverlay>

                <div className='GraphDisplay' style={{border: darkMode ?  '#3a3a3a solid 2px' : '#CBCBCB solid 2px', position: 'relative'}}>
                    <div className='GraphDisplay-Date-Container'>
                        <div style={darkMode ? {backgroundColor: '#353535', border: '2px solid #606060'} : {backgroundColor: '#D0D0D0', border: '2px solid #A0A0A0'}} className='GraphDisplay-Date-Category-Container'>
                            <div>Day</div>
                        </div>
                        <div style={darkMode ? {backgroundColor: '#353535', border: '2px solid #606060'} : {backgroundColor: '#D0D0D0', border: '2px solid #A0A0A0'}} className='GraphDisplay-Date-Date-Container'>
                            <div className='GraphDisplay-Date-Changer' onClick={handleDateBackClick}>
                                <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Left'></div>
                                <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Left'></div>
                            </div>
                            <div>{date}</div>
                            <div className='GraphDisplay-Date-Changer' onClick={handleDateForwardClick}>
                                <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Top Right'></div>
                                <div style={darkMode ? {backgroundColor: '#D9D9D9'} : {backgroundColor: '#808080'}} className='GraphDisplay-Date-Changer-Bar Bottom Right'></div>
                            </div>
                        </div>
                    </div>
                    <Droppable>
                        <GraphMaker handleSelectedItemRemove={handleSelectedItemRemove} chartObjects={chartObjects} data={data}></GraphMaker>
                    </Droppable>
                    <div className='GraphInfo' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <img width={25} src={darkMode ? questionMark : questionMarkBlack} alt="" />
                        <div className={darkMode ? 'PopupText' : 'PopupTextBlack'}>Drag and drop your desired data into the graph and compare.</div>
                    </div>
                </div>
            </div>
        </DndContext>
    );

}

export default GraphContainer