import { useEffect, useState } from "react";
import User from '../../../../api/user';
import { useNavigate } from 'react-router-dom';
import UserDisplay from './userDisplay';
import './usersList.css'

const UsersList = ({ToggleLoading}) => {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const userApi = User();
    const navigate = useNavigate();
    
    useEffect(() => {

        const getUsersFromAuth0 = async () => {
            try {
                setLoading(true)
                ToggleLoading(true)
                const userResponse = await userApi.getAllUsersInDatabase();
                
                setUsers(userResponse);
            } catch (error) {
                console.log(error);
            } finally {
                ToggleLoading(false)
                setLoading(false)
            }
        };

        getUsersFromAuth0();
        
    }, []);


    return (
        <div className="UserList-Container">
            {loading ? ( // Check if loading
                <div>Getting users...</div>
            ) : users.length > 0 ? (
                users.map((user, index) => (
                    <UserDisplay key={index} user={user}></UserDisplay> // Note: added key prop for each user
                ))
            ) : (
                <div>No users found</div>
            )}
        </div>
    )

}

export default UsersList;