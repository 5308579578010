import './dashboardNavbar.css'
import DashboardNavbarModeSlider from './Dashboard-Navbar-Darkmode/dashboardNavbarModeSlider';

const DashboardNavbar = ({user}) => {

    return (
        <div className="Dashboard-Navbar-container">
            <div className='Dashboard-Navbar-User-Container'>
                <div className='Dashboard-Navbar-User-Image-Container'>
                    <img className='Dashboard-Navbar-User-Image' src={user.picture ? user.picture : "https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg"} alt="" />
                </div>
                <div className='Dashboard-Navbar-User-Details-Container'>
                    <div className='Dashboard-Navbar-User-Details-Role'>{user['https://firnenergy.com/roles'] ? user['https://firnenergy.com/roles'] : "User"}</div>
                    <div className='Dashboard-Navbar-User-Details-Email'>{user.email ? user.email : "Placeholder"}</div>
                </div>
            </div>
            <div className='Dashboard-Navbar-Adjustables-Container'>
                <div className='Dashboard-Navbar-Adjustables-DarkMode-Container'>
                    <DashboardNavbarModeSlider works={user.email == "guy@firnenergy.com"}/>
                </div>
            </div>
        </div>
    )

}

export default DashboardNavbar;