import { useEffect, useState } from 'react';
import './batteryChart.css'
import { ResponsiveContainer, AreaChart, XAxis,YAxis, CartesianGrid, Tooltip, ReferenceLine, Area, CartesianAxis, Legend} from 'recharts'
import Policy from '../../../../api/policy';
import GraphValues from '../../../../api/graphValues';


import { Spin } from 'antd'


const BatteryChart = ({serialNr, TriggerActivity}) => {

    const policyApi = Policy();
    const graphValuesApi = GraphValues();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (serialNr) {
            
            //update every hour
            retrieveData()
           
        }
    }, [serialNr])


    const retrieveData = async() => {
        //SOC
        setLoading(true)
        const soc = await graphValuesApi.getGraphValue("soc", serialNr, new Date(), "5min");

        //fill soc
        let index = 0;
        let hour = 0;
        let minute = 0;
        let newSOC = [];
        for (let i = 0; i < 288; i++) {
            if (soc[index] && parseInt(soc[index].time.split(':')[0]) == hour && parseInt(soc[index].time.split(':')[1]) == minute) {
                newSOC.push({time: soc[index].time, value: soc[index].value})
                index++
                minute += 5
            } else {
                newSOC.push({time: `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}:00`, value: null})
                minute += 5
            }

            if (minute == 60) {
                minute = 0
                hour++
            }
        }

        const policys = await policyApi.getPolicys(serialNr, new Date());

        const datetime = new Date()
        let indexToGet = datetime.getHours() * 12 + Math.ceil(datetime.getMinutes() / 5)
        let activity = "Stand-By"
        if (policys[indexToGet]) {
            if (policys[indexToGet].powerSetpoint_W > 0) {
                activity = "Charging"
            } else if(policys[indexToGet].powerSetpoint_W < 0) {
                activity = "Discharging"
            }
        }
        TriggerActivity(activity)

        const dataFormat = []
        //reformat soc to 5 min intervals
        for (let i = 0; i < newSOC.length; i++) {

            const newItem = {time: newSOC[i].time, percentage: newSOC[i].value, policyNeg: policys.powerSetpoint_W[i] < 0 ? 100 : null, policyPos: policys.powerSetpoint_W[i] > 0 ? 100 : null}
            dataFormat.push(newItem)
        }
        setData(dataFormat)
        setLoading(false)
    } 

    const formatXAxis = (tickItem) => {
        const [hours] = tickItem.split(':');
        const hoursint = parseInt(hours)
        return `${hoursint}`;
    };

    return (
        <>
            {loading ? 
                
                <div style={{textAlign: 'center', marginTop: 20}}>
                    <Spin size="large" />
                </div>
            :    
        
            <ResponsiveContainer style={{fontSize: "1rem"}} width={"100%"} height="100%">
            
                <AreaChart data={data}
                margin={{ top: 10, left: 10 }}>
                    <defs>
                        <linearGradient id="bateryPercentage" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="30%" stopColor="#55D6C2" stopOpacity={1}/>
                            <stop offset="100%" stopColor="#82ca9d" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="policysNeg" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="30%" stopColor="#BC2924" stopOpacity={0.5}/>
                            <stop offset="100%" stopColor="#D83530" stopOpacity={1}/>
                        </linearGradient>
                        <linearGradient id="policysPos" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="30%" stopColor="#A8E6CF" stopOpacity={0.5}/>
                            <stop offset="100%" stopColor="#4CAF50" stopOpacity={1}/>
                        </linearGradient>
                    </defs>
                    <XAxis unit={"h"} dataKey={'time'} interval={36} axisLine={{strokeWidth: 2, color: "white"}} height={20} tickFormatter={formatXAxis}/>
                    {/* <XAxis dataKey={'time'} axisLine={{strokeWidth: 2, color: "white"}} height={20}/> */}
                    <YAxis tick={false} axisLine={{strokeWidth: 2}} width={2}/>
                    <Legend verticalAlign="bottom" iconSize={12} payload={[{ value: 'Charging', type: 'circle', color: '#7DD835' }, { value: 'Discharging', type: 'circle', color: '#D83535'}]}/>
                    <Area type="monotone" dataKey="percentage" stroke="#55D6C2" fill="url(#bateryPercentage)" />
                    <Area type="monotone" dataKey="policyNeg" stroke="" fill="url(#policysNeg)" />
                    <Area type="monotone" dataKey="policyPos" stroke="" fill="url(#policysPos)" />
                    {/* {data.map((entry, index) => (
                        entry.policy !== 0 && (
                            <ReferenceLine key={`refline-${index}`} x={entry.time - 1} stroke={entry.policy === 1 ? '#7DD835' : '#D83535'} strokeWidth={2} opacity={0.7}/>
                        )
                    ))} */}
                </AreaChart>
            </ResponsiveContainer>
            }
        </>
    )

}


export default BatteryChart;