import { useContext, useState } from 'react'
import './dashboardSavings.css'

import SavingsMeter from './SavingsMeter/savingsMeter'
import { DarkModeContext } from '../../../../context/DarkModeContext';

const DashboardSavings = () => {

    const {darkMode} = useContext(DarkModeContext);

    const [yesterDaySavings] = useState(18)
    const [todaySavings] = useState(8)

    return (
        <div className="dashboard__savings__container">
            <div className={`dashboard__savings__underdevelopment__container__${darkMode ? 'darkmode' : 'lightmode'}`}>
                <div className={`dashboard__savings__underdevelopment__message__${darkMode ? 'darkmode' : 'lightmode'}`}>
                    This section is currently under development and will be available soon.
                </div>
            </div>
            {/* <div className='dashboard__savings__header'>Savings</div>
            <div className='dashboard__savings__content'> */}
               
                {/* <div className='dashboard__savings__overdrive__container'>
                    <div className='dashboard__savings__overdrive__header'>Yesterday</div>
                    <div className='dashboard__savings__overdrive__meter__container'>
                        <SavingsMeter darkMode={darkMode} price={yesterDaySavings}/>
                    </div>
                    <div className='dashboard__savings__overdrive__value'>{yesterDaySavings}€</div>
                </div>
                <div className='dashboard__savings__overdrive__container'>
                    <div className='dashboard__savings__overdrive__header'>Today</div>
                    <div className='dashboard__savings__overdrive__meter__container'>
                        <SavingsMeter darkMode={darkMode} price={todaySavings}/>
                    </div>
                    <div className='dashboard__savings__overdrive__value'>{todaySavings}€</div>
                </div> */}
            {/* </div> */}
        </div>
    )

}

export default DashboardSavings