import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import UserDisplay from "../users/userDisplay";
import './installerUserList.css'
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import User from "../../../../api/user";

const InstallerUserList = ({ToggleLoading}) => {

    const userApi = User();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const { user, getAccessTokenSilently } = useAuth0();
    
    useEffect(() => {

        const fetchUserMetaData = async () => {

            try {
                ToggleLoading(true)
                setLoading(true)

                const domain = process.env.REACT_APP_AUTH0_DOMAIN

                const token = await userApi.getManagementApiToken();

                const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

                const metadataResponse = await axios.get(userDetailsByIdUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                //metadataResponse is filled with serialNr's
                const metaData = metadataResponse.data.app_metadata

                const users = await userApi.getUsersBySerialNr(metaData.clients)
                setUsers(users)


            } catch (error) {
                console.log(error);
                ToggleLoading(false)
                setLoading(false)
            }
            ToggleLoading(false)
            setLoading(false)
        };

        fetchUserMetaData();
        
    }, []);


    return (
        <div className="UserList-Container">
            {loading ? ( // Check if loading
                <div>Getting users...</div>
            ) : users.length > 0 ? (
                users.map((user, index) => (
                    <UserDisplay key={index} user={user}></UserDisplay> // Note: added key prop for each user
                ))
            ) : (
                <div>No users found</div>
            )}
        </div>
    )

}

export default InstallerUserList;